import {fetchQualificationStatusApi, fetchLendersApi} from "api/qualification";
import {OperationType} from "types/operation";
import {Operation} from "./Operation";
import {ResidentialApplicationQualificationsResponse} from "@pinch-financial/pinch-ui-components/dist/types/dto/residentialApplicationQualificationsResponse";
import {ApplicationStatusResponse} from "types/dto/applicationStatusResponse";

export const fetchQualificationStatusOperation = new Operation<ApplicationStatusResponse>(
  OperationType.fetchQualificationStatus,
  fetchQualificationStatusApi
);

export const fetchLendersOperation = new Operation<ResidentialApplicationQualificationsResponse[]>(
  OperationType.fetchLenders,
  fetchLendersApi
);
