import {useSelector} from "react-redux";
import {getContactInfo} from "store/selectors/basicInfo";
import {ProductFetchTimeoutPage as ProductFetchTimeoutPageUI} from "@pinch-financial/pinch-ui-components";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {useTranslation} from "react-i18next";

interface Props {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const ProductFetchTimeoutPage = ({onPrevPage, containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "ProductFetchTimeoutPage"});
  const {email} = useSelector(getContactInfo);

  return (
    <ProductFetchTimeoutPageUI
      containerRef={containerRef}
      header={t("header")}
      body={<span dangerouslySetInnerHTML={{__html: t("body", {email: email})!}} />}
      onBack={() => {
        onPrevPage?.();
      }}
      buttonText={i18next.t("backButton")}
      navigationBar={<NavigationBar />}
    />
  );
};

export default ProductFetchTimeoutPage;
