import {InviteSecondaryApplicantPageFormData} from "@pinch-financial/pinch-ui-components";
import {call, select, takeEvery} from "redux-saga/effects";
import {inviteSecondaryApplicantOperation} from "store/operation/basicInfo";
import {getConsolidatedBasicInfo} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {WithNavigate} from "types/ui";
import {submitInviteSecondary, submitNo} from "./InviteSecondaryApplicantPage.action";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
} from "store/selectors/navigationController";
import {getNextPageUrl} from "util/routeUtil";

export function* onSubmit({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;

  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);

  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );
  const nextPage: AppRouteUrl = yield call(
    getNextPageUrl,
    AppRouteUrl.INVITE_SECONDARY_APPLICANT,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );

  navigate && navigate(nextPage);
}

export function* onSubmitWithSecondaryApplicant({
  payload,
}: WithPayload<InviteSecondaryApplicantPageFormData>) {
  const {navigate, ...userRequest} = payload;
  const primaryUserId: string = yield select(getUserId);
  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    inviteSecondaryApplicantOperation.saga,
    primaryUserId,
    userRequest
  );
}

export default function* inviteSecondaryApplicantSaga() {
  yield takeEvery(submitInviteSecondary, onSubmitWithSecondaryApplicant);
  yield takeEvery(submitNo, onSubmit);
}
