import {useLocation} from "react-router-dom";
import {root} from "./ErrorPage.module.scss";
import {WritableError} from "types/operation";
import {ErrorType, UNKNOWN_ERROR} from "types/error";
import {MessageStatus} from "types/MessageStatus";
import {GeneralPageTemplate} from "@pinch-financial/pinch-ui-components";

export interface LocationStateInterface {
  error: ErrorType;
}

const getMessageAndStatus = (error: ErrorType): MessageStatus => {
  let message: string = UNKNOWN_ERROR;
  let status: number | undefined = undefined;

  if (error) {
    if (typeof error === "string") {
      message = error;
    } else if (typeof error === "object") {
      // WritableError | ApiError
      const writableError = error as WritableError;
      if (typeof writableError?.message === "string") {
        message = writableError?.message;
      } else {
        message = writableError.message.message!;
      }

      message = message || UNKNOWN_ERROR;
      status = writableError.status;
    }
  }

  return [message, status];
};

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const ErrorPage = ({containerRef}: Props) => {
  const location = useLocation();
  const redirectState: ErrorType = location?.state;
  const [message, status] = getMessageAndStatus(redirectState);
  const title = [status, "Error"].filter(Boolean).join(" ");

  return (
    <GeneralPageTemplate ref={containerRef}>
      <div className={root}>
        <h3>{title}</h3>
        <div>{message}</div>
      </div>
    </GeneralPageTemplate>
  );
};

export default ErrorPage;
