import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFixedT} from "util/languageUtil";
import {initialize, registerPrimaryUser} from "./SignUpPage.action";
import {getIsAnyOperationPending, getOperationFailure} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {Typography, Link} from "@mui/material";
import {AppRouteUrl, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import {fetchPolicyApi} from "api/basicInfo";
import {getSelectedProviderName, getWhitelabelId} from "store/selectors/whitelabel";
import {removeNavigationError} from "components/NavigationBar/NavigationBar.action";
import {SignUpPage as SignUpPageUI, SignUpPageFormData} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import i18next from "i18next";
import {useNavigate} from "react-router-dom";

const fixedT = getFixedT("signUpPage");

interface SignUpPageProps {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SignUpPage = ({onPrevPage, containerRef}: SignUpPageProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPending = useSelector(getIsAnyOperationPending)(OperationType.registerPrimaryUser);
  const failureError = useSelector(getOperationFailure)(OperationType.registerPrimaryUser);
  const whiteLabelId = useSelector(getWhitelabelId);
  const selectedProviderName = useSelector(getSelectedProviderName);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [policyContent, setPolicyContent] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string>("");
  const [checked, setChecked] = React.useState(false);

  const onSubmit = (values: SignUpPageFormData) => {
    setError("");
    dispatch(removeNavigationError());

    trackEvent(TrackingEventType.signUpPageSubmit);
    dispatch(
      registerPrimaryUser({
        ...values,
        navigate,
      })
    );
  };

  useEffect(() => {
    const fetchPolicy = async () => {
      const {data: privacyPolicy, error} = await fetchPolicyApi(whiteLabelId);
      if (error) {
        console.error("Failed to fetch policy", error);
      } else {
        setPolicyContent(privacyPolicy);
      }
    };

    fetchPolicy();
  }, [whiteLabelId]);

  useEffect(() => {
    if (failureError) {
      if (failureError?.status && (failureError.status === 400 || failureError.status === 409)) {
        setError(fixedT("error_emailExists"));
      } else if (failureError?.status && failureError.status != 200) {
        setError(fixedT("error", {context: failureError?.status}));
      }
    }
  }, [failureError]);

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  const handleSlideTransition = () => {
    setChecked(!checked);
  };

  return (
    <SignUpPageUI
      containerRef={containerRef}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar />}
      header={fixedT("header")}
      subheader={<span>{fixedT("subheader.start")}<a tabIndex={checked ? 1 : 0} href={AppRouteUrl.SIGN_IN}>{fixedT("subheader.link")}</a>{fixedT("subheader.end")}</span>}
      description={fixedT("description")}
      isPending={isPending}
      privacyPolicy={policyContent}
      privacyPolicyTitle={fixedT("policyTitle")}
      privacyPolicyError={fixedT("errorPolicy")}
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      checked={checked}
      handleSlideTransition={handleSlideTransition}
      firstNameLabel={fixedT("fields.firstName.label")}
      firstNamePlaceholder={fixedT("fields.firstName.placeholder")}
      lastNameLabel={fixedT("fields.lastName.label")}
      lastNamePlaceholder={fixedT("fields.lastName.placeholder")}
      emailLabel={fixedT("fields.email.label")}
      emailPlaceholder={fixedT("fields.email.placeholder")}
      phoneNumberLabel={fixedT("fields.phoneNumber.label")}
      phoneNumberPlaceholder={fixedT("fields.phoneNumber.placeholder")}
      privacyPolicyLinkText={
        <Typography sx={{fontSize: {laptop: "16px", mobile: "15px"}}}>
          {fixedT("termsAndConditions")}{" "}
          <Link
            tabIndex={checked ? 2 : 0}
            onClick={handleSlideTransition}
            onKeyDown={(e) => {
              e.key === "Enter" && handleSlideTransition();
            }}>{fixedT("termsAndConditionsLink")}</Link>
        </Typography>
      }
      optInText={fixedT("emailOptIn", {name: selectedProviderName})}
      backButtonText={i18next.t("backButton")}
      nextButtonText={fixedT("forwardButton")}
      pageError={error}
      // Schema props
      missingFirstName={fixedT("messages.missingFirstName")}
      missingLastName={fixedT("messages.missingLastName")}
      invalidEmail={fixedT("messages.invalidEmail")}
      invalidPhoneNumber={fixedT("messages.invalidPhoneNumber")}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default SignUpPage;
