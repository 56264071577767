import {SagaIterator} from "redux-saga";
import {call, fork, select, takeEvery} from "redux-saga/effects";
import {
  setPasswordOperation,
  signOutOperation,
  verifyRecoveryTokenOperation,
} from "store/operation/operations";
import {getEmailFromRecovery, getStateTokenFromRecovery} from "store/selectors/auth";

import {SagaResult, WithPayload} from "types/basic";
import {
  initialize,
  InitializePayload,
  SetPasswordPagePayload,
  submit,
} from "./SetPasswordPage.action";
import i18next from "i18next";
import {Operation} from "store/operation/Operation";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation} from "store/operation/financialInstitution";

export function* initializePageData({
  payload: {token, appId},
}: WithPayload<InitializePayload>): any {
  if (appId) {
    yield fork(fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation.saga, appId);
  }

  const result = yield call(verifyRecoveryTokenOperation.saga, token);
  if (!appId && Operation.isSuccess(result)) {
    const userExternalAuthId = result?.user?.id;
    yield call(
      fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation.saga,
      null,
      userExternalAuthId
    );
  }
}

export function* setPassword({
  payload: {password, navigate},
}: WithPayload<SetPasswordPagePayload>): any {
  const whiteLabelId: string = yield select(getWhitelabelId);
  const email: string = yield select(getEmailFromRecovery);
  const stateToken: string = yield select(getStateTokenFromRecovery);
  const result: SagaResult<void> = yield call(
    setPasswordOperation.saga,
    whiteLabelId,
    password,
    email,
    stateToken,
    i18next.language
  );

  if (Operation.isSuccess(result)) {
    yield call(signOutOperation.saga);
  }
}

export default function* setPasswordSaga(): SagaIterator {
  yield takeEvery(initialize, initializePageData);
  yield takeEvery(submit, setPassword);
}
