import {createReducer} from "@reduxjs/toolkit";
import {fetchThemeOperation, signOutOperation} from "store/operation/operations";
import {ThemeState} from "types/theme";

const initialState: ThemeState = null;

const themeReducer = createReducer<ThemeState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.pending, () => initialState)
    .addCase(fetchThemeOperation.success, (state, {payload: themeDto}) => themeDto)
);

export default themeReducer;
