import * as styles from "./CheckboxControlled.module.scss";
import {Checkbox, CheckboxProps, FormControlLabel, Typography} from "@mui/material";
import {useState} from "react";
import {Controller, FieldValues, UseFormReturn} from "react-hook-form";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {WithChildren} from "types/ui";
import {trackEvent} from "util/eventUtil";

interface CheckboxControlledProps<T extends FieldValues = any, C = any>
  extends CheckboxProps,
    WithChildren {
  formHook: UseFormReturn<T, C>;
  name: string;
  required?: boolean;
  triggerOnChange?: boolean; // React-hook-form workaround for dropdown change not updating the validation/UI
  trackingEvent?: TrackingEventType;
  label?: string;
}

const CheckboxControlled = ({
  formHook,
  trackingEvent,
  // MUI props
  name,
  label,
}: CheckboxControlledProps) => {
  const {control, trigger, getFieldState, formState} = formHook;
  const {error, invalid, isTouched} = getFieldState(name);

  const shouldDisplayError =
    (formState.submitCount > 0 && Boolean(error?.message)) ||
    (isTouched && invalid && formState.isDirty);

  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckboxClick = (checked: boolean, field: any) => {
    setChecked(checked);
    if (trackingEvent) trackEvent(trackingEvent);
    field.onChange(checked);
  };

  return (
    <Controller
      key={name}
      name={name}
      control={control}
      render={({field}) => (
        <>
          <div className={styles.root}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={checked}
                  onChange={(e) => handleCheckboxClick(e.target.checked, field)}
                  inputProps={{"aria-label": "controlled"}}
                  sx={{"& .MuiSvgIcon-root": {fontSize: 20}}}
                />
              }
              sx={{"& .MuiFormControlLabel-label": {fontSize: 13}}}
              label={label}
            />
          </div>
          {shouldDisplayError && (
            <Typography sx={{margin: "-12px 0px 0px 29px"}} color="#d32f2f" variant="caption">
              {error?.message || ""}
            </Typography>
          )}
        </>
      )}
    />
  );
};

export default CheckboxControlled;
