import {MortgageInfoPageFormData, WithNavigatePayload} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "MortgageInfoPage";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const submitMortgageInfo = createAction<WithNavigatePayload<MortgageInfoPageFormData>>(
  actionTypeOf(namespace, "submit")
);

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
