import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {AllDocStateAndRequirements} from "types/docStateAndRequirements";
import {DocumentType} from "types/enums/documentType";
import {WithNavigate} from "types/ui";

const namespace = "UploadDocuments";

export interface UploadDocumentsPayload {
  file: File;
  documentType?: DocumentType;
  progressCallback?: (progress: number) => void;
}

export type InitializePayload = WithNavigate & {
  docStateAndRequirements: AllDocStateAndRequirements;
};

export const initialize = createAction<InitializePayload>(actionTypeOf(namespace, "initialize"));

export const submitFile = createAction<UploadDocumentsPayload>(actionTypeOf(namespace, "submit"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);

export const navigateNextStep = createAction<WithNavigate>(actionTypeOf(namespace, "navigateNext"));

export const acceptMissingDocumentUploadWarning = createAction<boolean>(
  actionTypeOf(namespace, "acceptMissingDocumentUploadWarning")
);

export const openUserAcceptedDocumentUploadWarningModal = createAction<boolean>(
  actionTypeOf(namespace, "openUserAcceptedDocumentUploadWarningModal")
);
