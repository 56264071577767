import {BankOrBrokerPage as BankOrBrokerPageUI} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {nextWithBank, nextWithBroker} from "./BankOrBrokerPage.action";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {getBankProvider, getLenderProvider} from "store/selectors/whitelabel";
import {useTranslation} from "react-i18next";
import {safeArray} from "components/utils/listUtil";
import {ProductSummary} from "@pinch-financial/pinch-ui-components";
import {formatAsPercentage} from "util/numberUtil";
import {useEffect} from "react";
import {trackEvent} from "util/eventUtil";
import i18next from "i18next";
import {getIconDisplayMapper, getIsRateDisplayed} from "store/selectors/configurations";
import {getContactInfo} from "store/selectors/basicInfo";
import SaveButton from "components/SaveButton/SaveButton";
import {getIsPrimaryLogoDisplayed} from "store/selectors/providerconfigurations";
import {getIsOperationSuccess} from "store/selectors/operation";
import {OperationType} from "types/operation";

interface Props {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const BankOrBrokerPage = ({onPrevPage, containerRef}: Props) => {
  const {t} = useTranslation("", {keyPrefix: "BankOrBrokerPage"});
  const isKeyboardOpen = useDetectKeyboardOpen();

  const iconDisplayMapper = useSelector(getIconDisplayMapper);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isRateDisplayed = useSelector(getIsRateDisplayed) || false;
  const isPrimaryLogoDisplayed = useSelector(getIsPrimaryLogoDisplayed);

  const {products: bankProducts, displayRate: bankDisplayRate} = useSelector(getBankProvider) || {};
  const {products: brokerProducts, displayRate: brokerDisplayRate} =
    useSelector(getLenderProvider) || {};
  const {email} = useSelector(getContactInfo);
  const isSuccess = useSelector(getIsOperationSuccess)(OperationType.fetchProviderConfigurations);

  useEffect(() => {
    // fetch backend for page texts and replace the i18next t function
  }, []);

  return (
    <BankOrBrokerPageUI
      containerRef={containerRef}
      trackEvent={trackEvent}
      onClickBank={(bankProviderId) =>
        dispatch(nextWithBank({providerId: bankProviderId, navigate}))
      }
      onClickBroker={() => dispatch(nextWithBroker({navigate}))}
      onPrevPage={onPrevPage}
      isMobileKeyboardOpen={isKeyboardOpen}
      loading={!isSuccess} // Prevents showing products when they are not suppose to be shown
      loadingMessage=""
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      header={t("header")!}
      description={t("description")!}
      tipHeader={t("tipHeader")!}
      tipBody={<span dangerouslySetInnerHTML={{__html: t("tipBody", {email: email})!}} />}
      iconAlt={t("iconAlt")}
      bankSectionHeader1={t("bankSectionHeader1")!}
      bankSectionHeader2={
        isRateDisplayed && bankDisplayRate
          ? t("displayRate", {rate: formatAsPercentage(bankDisplayRate * 100)})
          : ""
      }
      bankDisplayRate={bankDisplayRate ?? 0}
      brokerSectionHeader1={t("brokerSectionHeader1")!}
      brokerSectionHeader2={
        isRateDisplayed && brokerDisplayRate
          ? t("displayRate", {rate: formatAsPercentage(brokerDisplayRate * 100)})
          : ""
      }
      brokerDisplayRate={brokerDisplayRate ?? 0}
      banks={safeArray(bankProducts).map((prod: ProductSummary) => {
        const {icon, background} = iconDisplayMapper(prod);
        return {
          id: prod.financialInstitutionPublicId,
          iconSrc: icon,
          backgroundColor: `rgb(${background})`, // Expects BE format like: `255, 255, 255`
          name: prod.productName, // Rectangular image fits better here
        };
      })}
      brokers={safeArray(brokerProducts).map((prod: ProductSummary) => {
        const {icon, background} = iconDisplayMapper(prod);
        return isPrimaryLogoDisplayed
          ? {
              iconSrc: `${window._env_.REACT_APP_IMGIX_DOMAIN}/${prod.financialInstitutionLogo}`,
              backgroundColor: `rgb(${prod.financialInstitutionBackgroundColor})`,
              name: prod.financialInstitutionName, // Small and square/circle image fits better here
            }
          : {
              iconSrc: icon,
              backgroundColor: `rgb(${background})`,
              name: prod.productName, // Small and square/circle image fits better here
            };
      })}
      backButtonText={i18next.t("backButton")!}
      usePrimaryLogo={isPrimaryLogoDisplayed}
    />
  );
};

export default BankOrBrokerPage;
