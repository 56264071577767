import {useTheme} from "@mui/material";
import {useSelector} from "react-redux";
import useOnResize from "hooks/useOnResize/useOnResize";
import {getHeaderLink} from "store/selectors/theme";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import * as styles from "./CompanyLogo.module.scss";
import {
  getWhiteLabelLogoUrlEn,
  getWhiteLabelLogoUrlFr,
  getWhitelabelName,
} from "store/selectors/whitelabel";
import {isCurrentFrench} from "util/languageUtil";
import {isNullOrUndefined} from "components/utils/booleanUtil";

interface CompanyLogoProps {}

const CompanyLogo = ({}: CompanyLogoProps) => {
  const theme = useTheme();
  const {isScreenMobileWidth} = useOnResize(theme.breakpoints);
  const logoImgUrlEn = useSelector(getWhiteLabelLogoUrlEn);
  const logoImgUrlFr = useSelector(getWhiteLabelLogoUrlFr);
  const headerLink = useSelector(getHeaderLink);
  const whiteLabelName = useSelector(getWhitelabelName);

  return (
    <div className={isScreenMobileWidth ? styles.rootMobile : styles.root}>
      <img
        src={isCurrentFrench() && !isNullOrUndefined(logoImgUrlFr) ? logoImgUrlFr : logoImgUrlEn}
        alt={whiteLabelName}
        aria-label={whiteLabelName}
        onClick={() => {
          if (headerLink) {
            trackEvent(TrackingEventType.clickHomePage);
            window.location.href = headerLink;
          }
        }}
      />
    </div>
  );
};

export default CompanyLogo;
