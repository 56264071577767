import {createAction} from "@reduxjs/toolkit";
import {
  OperationFailurePayload,
  OperationPendingPayload,
  OperationSuccessPayload,
  OperationStatus,
  OperationType,
} from "../../types/operation";
import {actionTypeOf} from "./util";

const namespace = "Operation";

export const setOperationPending = createAction<OperationPendingPayload>(
  actionTypeOf(namespace, OperationStatus.pending)
);

export const setOperationSuccess = createAction<OperationSuccessPayload>(
  actionTypeOf(namespace, OperationStatus.success)
);

export const setOperationFailure = createAction<OperationFailurePayload>(
  actionTypeOf(namespace, OperationStatus.failure)
);

export const unsetOperations = createAction<OperationType[]>(
  actionTypeOf(namespace, "unsetOperations")
);

export const unsetOperation = createAction<OperationType>(
  actionTypeOf(namespace, "unsetOperation")
);
