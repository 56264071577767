import {useEffect, useState} from "react";
import {ApplicantRelationshipType} from "types/enums/applicantRelationshipType";
import {getFixedT} from "util/languageUtil";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getUserId} from "store/selectors/user";
import {
  getContactInfo,
  getIsLocked,
  getIsPrimaryAndHasSecondary,
  getIsSecondary,
} from "store/selectors/basicInfo";
import {submitInviteSecondary, submitNo} from "./InviteSecondaryApplicantPage.action";
import {getOperationFailure, getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {trackEvent, trackEventMemoized} from "util/eventUtil";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {
  InviteSecondaryApplicantPage as InviteSecondaryApplicantPageUI,
  InviteSecondaryApplicantPageFormData,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import i18next from "i18next";
import SaveButton from "components/SaveButton/SaveButton";

const fixedT = getFixedT("inviteSecondaryApplicantPage");

interface Props {
  className?: string;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const InviteSecondaryApplicantPage = ({
  className = "",
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locked = useSelector(getIsLocked);
  const primaryUserId = useSelector(getUserId);
  const isSecondary = useSelector(getIsSecondary);
  const operationFailure = useSelector(getOperationFailure)(OperationType.inviteSecondaryApplicant);
  const isPrimaryAndHasSecondary: boolean = useSelector(getIsPrimaryAndHasSecondary);
  const {email} = useSelector(getContactInfo);

  const {isPending} = useSelector(getOperationResult)(OperationType.inviteSecondaryApplicant);
  const [otherError, setOtherError] = useState<string>(
    !primaryUserId ? fixedT("errorMessages.userIdMissing")! : ""
  );

  function validate(): boolean {
    if (isSecondary) {
      setOtherError(fixedT("errorMessages.primaryApplicantError")!);
      return false;
    }
    if (!primaryUserId) {
      setOtherError(fixedT("errorMessages.userIdMissing")!);
      return false;
    }
    return true;
  }

  let operationFailureMessage = "";
  if (
    operationFailure?.status &&
    (operationFailure.status === 400 || operationFailure.status === 409)
  ) {
    operationFailureMessage = fixedT("errorMessages.error_emailExists");
  } else if (operationFailure?.status && operationFailure.status != 200) {
    operationFailureMessage = fixedT("errorMessages.error", {context: operationFailure?.status});
  }

  const onSubmit = (values: InviteSecondaryApplicantPageFormData) => {
    if (validate()) {
      trackEventMemoized(TrackingEventType.inviteSecondaryApplicantSubmit);
      dispatch(
        submitInviteSecondary({
          navigate,
          ...values,
        })
      );
    }
  };

  const onSubmitNo = () => {
    dispatch(
      submitNo({
        navigate,
      })
    );
  };

  const relationshipOptions = (typeVal: ApplicantRelationshipType) => {
    return fixedT("fields.relationship.options", {context: typeVal});
  };

  const isKeyboardOpen = useDetectKeyboardOpen();

  return (
    <InviteSecondaryApplicantPageUI
      containerRef={containerRef}
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      isPending={isPending}
      locked={locked}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      isPrimaryAndHasSecondary={isPrimaryAndHasSecondary}
      header={fixedT("header")}
      description={fixedT("description")}
      tipHeader={fixedT("tipHeader")}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody", {email: email})}} />}
      backButtonText={i18next.t("backButton")}
      firstNameLabel={fixedT("fields.firstName.label")}
      firstNamePlaceholder={fixedT("fields.firstName.placeholder")}
      lastNameLabel={fixedT("fields.lastName.label")}
      lastNamePlaceholder={fixedT("fields.lastName.placeholder")}
      emailLabel={fixedT("fields.email.label")}
      emailPlaceholder={fixedT("fields.email.placeholder")}
      relationshipLabel={fixedT("fields.relationship.label")}
      relationshipOptions={relationshipOptions}
      completedHeader={fixedT("completionTitle")}
      completedOnNextPage={() => {
        trackEvent(TrackingEventType.inviteSecondaryApplicantSubmit);
        onNextPage && onNextPage();
      }}
      completedNextButtonText={fixedT("forwardButton")}
      completedDetails={fixedT("completionDetailsPrimaryApplicant")}
      firstNameMissing={fixedT("errorMessages.firstNameMissing")}
      lastNameMissing={fixedT("errorMessages.lastNameMissing")}
      validEmailMissing={fixedT("errorMessages.validEmailMissing")}
      relationshipMissing={fixedT("errorMessages.relationshipMissing")}
      pageError={otherError || operationFailureMessage}
      nextButtonText={fixedT("forwardButton")}
      yesText={fixedT("yesText")}
      noText={fixedT("noText")}
      onNoClick={onSubmitNo}
    />
  );
};

export default InviteSecondaryApplicantPage;
