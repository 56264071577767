import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "CreditScorePage";

export const initialize = createAction(actionTypeOf(namespace, "initialize"));

export type SubmitCreditScorePagePayload = {
  onNextPage?: () => any;
} & Pick<ResidentialApplicationBasicInfoRequest, "creditScore" | "dateOfBirth">;

export const submit = createAction<SubmitCreditScorePagePayload>(actionTypeOf(namespace, "submit"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
