import {call, put, select, takeEvery} from "redux-saga/effects";
import {onAssignMortgageProvider, onFetchAvailableProducts} from "../Root/Root.saga";
import {ProvinceSelectPagePayload, submit} from "./ProvinceSelectPage.action";
import {WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {getConsolidatedBasicInfo} from "store/selectors/basicInfo";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";

export function* onSubmit({
  payload: {selectedProvince, navigate, onNextPage},
}: WithPayload<ProvinceSelectPagePayload>) {
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails: {
      ...basicInfo?.mortgageDetails,
      province: selectedProvince,
    },
  };
  yield put(saveBasicInfo(basicInfoRequest));

  yield call(onFetchAvailableProducts, navigate);
  yield call(onAssignMortgageProvider);
  onNextPage?.();
}

export default function* provinceSelectPageSaga() {
  yield takeEvery(submit, onSubmit);
}
