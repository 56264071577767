import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitLoanTypeOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {
  ResidentialApplicationBasicInfoRequest,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {next} from "./MortgageTypePage.action";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
  getNavigationHistory,
} from "store/selectors/navigationController";
import {getNextPageUrl} from "util/routeUtil";
import {getConsolidatedBasicInfo} from "store/selectors/basicInfo";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {NavigationHistory} from "types/NavigationController";

function* onNext({payload: {data: loanType, navigate}}: WithPayload<WithNavigatePayload>) {
  const userId: string = yield select(getUserId);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);

  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails: {
      ...basicInfo.mortgageDetails,
      loanType,
    },
  };
  yield put(saveBasicInfo(basicInfoRequest));

  let nextPageUrl = getNextPageUrl(
    AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );
  const navigationHistory: NavigationHistory = yield select(getNavigationHistory);
  // if previous page was mortgage info page then we were redirected and we must navigate back there
  if (
    navigationHistory[navigationHistory.length - 2] ===
    AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION
  ) {
    nextPageUrl = AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION;
  }
  if (!userId) {
    yield call(navigate, nextPageUrl);
    return;
  }

  if (userId) {
    // For existing account where loan type is missing somehow.
    const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
      submitLoanTypeOperation.saga,
      userId,
      {mortgageDetails: {loanType}},
      Step.LOAN_TYPE
    );

    if (Operation.isError(result)) {
      console.error("Failed to submit loan type", result);
      return;
    }
  }
  yield call(navigate, nextPageUrl);
}

export default function* mortgageSelectionSaga() {
  yield takeEvery(next, onNext);
}
