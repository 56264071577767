import {useEffect, useRef, useState} from "react";
import "dayjs/locale/fr";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getHasFeatureSin} from "store/selectors/theme";
import {
  getBasicInfo,
  getContactInfo,
  getCreditReportFailedAttempts,
  getHasCreditSuccess,
  getIsLocked,
  getIsSinRequired,
  getNamesConsolidated,
} from "store/selectors/basicInfo";
import {EquifaxPageFormData, submit} from "./EquifaxPage.action";
import {getOperationFailure, getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getFixedT} from "util/languageUtil";
import {trackEvent} from "util/eventUtil";
import {
  AppRouteUrl,
  OnNextPage,
  PageRef,
  TrackingEventType,
} from "@pinch-financial/pinch-ui-components";
import i18next from "i18next";
import {EquifaxPage as EquifaxPageUI, LanguageType} from "@pinch-financial/pinch-ui-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";

const fixedT = getFixedT("equifaxPage");

interface Props {
  className?: () => any;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const EquifaxPage: React.FC<Props> = ({
  className = "",
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}) => {
  const pageRef = useRef<PageRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const basicInfo = useSelector(getBasicInfo);
  const {isPending} = useSelector(getOperationResult)(OperationType.submitLegalNamesSin);
  const failureError = useSelector(getOperationFailure)(OperationType.submitLegalNamesSin);
  const {email} = useSelector(getContactInfo);
  const locked = useSelector(getIsLocked);
  const hasCreditSuccess = useSelector(getHasCreditSuccess);
  const creditReportFailedAttempts = useSelector(getCreditReportFailedAttempts);
  const previousURL = useSelector(getPreviousUrlFromHistory);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const hasSinFeature = useSelector(getHasFeatureSin);
  const SINRequired = useSelector(getIsSinRequired);
  const {firstName, lastName} = useSelector(getNamesConsolidated);
  const [isSkipButton, setIsSkipButton] = useState(false);

  const [fieldError, setFieldError] = useState<string>();

  const onSubmit: OnNextPage<EquifaxPageFormData> = (values, event, shouldStay) => {
    if (isSkipButton && !shouldStay) {
      trackEvent(TrackingEventType.eqClickSkipButton);
      onNextPage?.();
      return;
    }
    !shouldStay && trackEvent(TrackingEventType.eqClickNextButton);
    setFieldError("");
    dispatch(
      submit({
        data: {
          ...values,
          dateOfBirth: new Date(values?.dateOfBirth!).toISOString().split("T")[0], // QM only accepts it in this format; yyyy-mm-dd,
        } as EquifaxPageFormData,
        navigate,
        onNextPage,
        shouldStay,
      })
    );
  };

  useEffect(() => {
    if (hasCreditSuccess) {
      setFieldError("");
    } else {
      // Failed to pull credit report once, allow one retry.
      if (creditReportFailedAttempts == 1 || failureError?.status == 400) {
        setFieldError(fixedT("badInputError")!);
      }

      if (failureError?.status == 500) {
        setIsSkipButton(true);
        setFieldError(fixedT("unknownError")!);
      }
    }
  }, [failureError, creditReportFailedAttempts, hasCreditSuccess]);

  const buttonText = isSkipButton ? fixedT("skipButton") : fixedT("nextButton");

  return (
    <EquifaxPageUI
      containerRef={containerRef}
      ref={pageRef}
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      nextButtonText={buttonText}
      backButtonText={i18next.t("backButton")}
      header={fixedT("header")}
      description={fixedT("description")}
      tipHeader={fixedT("tipHeader")}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody", {email: email})}} />}
      locked={locked || hasCreditSuccess}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      legalFirstNameLabel={fixedT("form.legalFirstName")}
      legalLastNameLabel={fixedT("form.legalLastName")}
      dateOfBirthLabel={fixedT("form.dateOfBirth")}
      sinLabel={fixedT("form.sin")}
      pageError={fieldError}
      isPending={isPending}
      hasSinFeature={hasSinFeature}
      SINRequired={SINRequired}
      isFrench={i18next.language === LanguageType.FR_CA}
      // Schema props
      firstName={firstName || ""}
      lastName={lastName || ""}
      dateOfBirth={basicInfo?.dateOfBirth}
      missingLegalFirstName={fixedT("form.messages.missing", {context: "legalFirstName"})}
      missingLegalLastName={fixedT("form.messages.missing", {context: "legalLastName"})}
      invalidBirthDate={fixedT("form.messages.invalidBirthDate")}
      invalidUnderage={fixedT("form.messages.invalidUnderage")}
      missingSinNumbers={fixedT("form.messages.missing", {context: "sinNumbers"})}
      invalidSocialInsuranceNumber={fixedT("form.messages.invalidSocialInsuranceNumber")}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default EquifaxPage;
