import {PropertyAddressDto, WithNavigatePayload} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "UnresolvedAVMPage";

export type SubmitUnresolvedAVMPagePayload = WithNavigate & Partial<PropertyAddressDto>;

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const submit = createAction<WithNavigatePayload<SubmitUnresolvedAVMPagePayload>>(
    actionTypeOf(namespace, "submit")
);

