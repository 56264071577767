import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitEmploymentInfoOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {EmploymentInfoFormData, submitEmployment} from "./EmploymentInfoPage.action";
import {
  ResidentialApplicationBasicInfoRequest,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {saveBasicInfo} from "store/actions/applicantMeta.action";

export function* onSubmit({payload}: WithPayload<WithNavigatePayload<EmploymentInfoFormData>>) {
  const {
    onNextPage,
    shouldStay,
    data: {grossIncome, ...employmentHistory},
  } = payload;
  const userId: string = yield select(getUserId);
  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    grossIncome,
    employmentHistory,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage?.();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitEmploymentInfoOperation.saga,
    userId,
    basicInfoRequest,
    Step.EMPLOYMENT_INFO
  );
  if (Operation.isSuccess(result) && !shouldStay) {
    onNextPage?.();
  }
}

export default function* employmentInfoSaga() {
  yield takeEvery(submitEmployment, onSubmit);
}
