import {
  SelfReportedLiabilitiesPageFormData,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "SelfReportedLiabilitiesPage";

export interface OtherEntry {
  amount?: number;
  description?: string;
}

export const submit = createAction<WithNavigatePayload<SelfReportedLiabilitiesPageFormData>>(
  actionTypeOf(namespace, "submit")
);

export const acceptMissingLiabilitiesDataWarning = createAction<boolean>(
  actionTypeOf(namespace, "acceptMissingLiabilitiesDataWarning")
);

export const openUserAcceptedLiabilitiesDataWarningModal = createAction<boolean>(
  actionTypeOf(namespace, "openUserAcceptedLiabilitiesDataWarningModal")
);
