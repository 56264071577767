import {createReducer} from "@reduxjs/toolkit";
import {
  verifyRecoveryTokenOperation,
  fetchAuthorizedUserOperation,
  setPasswordOperation,
  signInOperation,
  signOutOperation,
} from "store/operation/operations";
import {AuthState} from "types/auth";

const initialState: AuthState = {} as AuthState;

const authReducer = createReducer<AuthState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.pending, () => initialState)
    .addCase(fetchAuthorizedUserOperation.success, (state, {payload: authUser}) => {
      state.user = authUser;
    })
    .addCase(signInOperation.success, (state, {payload: authUser}) => {
      state.user = authUser;
    })
    .addCase(verifyRecoveryTokenOperation.success, (state, {payload: authnTransaction}) => {
      state.recoveryTransaction = authnTransaction;
    })
    .addCase(setPasswordOperation.success, (state, {payload: setPasswordResponse}) => {
      state.setPassword = setPasswordResponse;
    })
);

export default authReducer;
