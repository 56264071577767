import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";
import {
  fetchThemeOperation,
  fetchAuthorizedUserOperation,
  fetchUserApiOperation,
  fetchWhiteLabelConfigurationsOperation,
  fetchProviderConfigurationsOperation,
} from "store/operation/operations";
import {initialize} from "./Root.action";
import i18next, {t} from "i18next";
import {getHasUserAccount, getPreferredLanguage, getUserId} from "store/selectors/user";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {getIsAuthorized} from "store/selectors/auth";
import {
  getBrokerCode,
  getMortgageProviderIdFromAppOrSelected,
  getMortgageProviderIdFromApp,
  getConsolidatedBasicInfo,
} from "store/selectors/basicInfo";
import {storeParams, getAddress, getSelectedProvince} from "util/localStorageUtil";
import {SagaResult, WithPayload} from "types/basic";
import {WithNavigate} from "types/ui";
import {USER_ACCOUNT_NOT_REQUIRED_URLS} from "types/route";
import {Theme} from "types/theme";
import {
  fetchMortgageProvidersOperation,
  fetchWhitelabelOperation,
} from "store/operation/whitelabel";
import {getPlatform} from "store/selectors/theme";
import {
  getDefaultProviderId,
  getProducts,
  getWhitelabelId,
  pickProviderForDefaultProvider,
} from "store/selectors/whitelabel";
import {LanguageType} from "types/enums/languageType";
import {onChangeLanguage} from "components/NavigationBar/NavigationBarActionSection/NavigationBarActionSection.saga";
import {getSearchParamLocale} from "components/utils/urlUtil";
import {getLanguage} from "store/selectors/configurations";
import {
  initializeNavbarStatus as initializeNavbar,
  fetchInitRouteSequenceNavStatus as initializeActiveRouteSequence,
} from "components/NavigationBar/NavigationBar.saga";
import {WhiteLabelProductGroupDto} from "types/dto/WhiteLabelProductGroupDto";
import {ApiError} from "types/ApiError";
import {Configuration} from "types/configurations";
import {ProductSummary} from "@pinch-financial/pinch-ui-components";
import {WhiteLabelDetailsDto} from "types/dto/WhiteLabelDetailsDto";
import {setDefaultProviderId} from "store/actions/whiteLabel.action";
import {FinancialInstitutionType} from "@pinch-financial/pinch-ui-components";
import {
  AppRouteUrl,
  QueryParamKeys,
  ResidentialApplicationBasicInfoRequest,
  UUID,
} from "@pinch-financial/pinch-ui-components";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {Province} from "types/enums/province";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {fetchAppAssociatedFinancialInstitutionOperation} from "store/operation/financialInstitution";
import {Operation} from "store/operation/Operation";
import {FinancialInstitutionBasicResponse} from "types/financialInstitutionBasicResponse";

export function* storeUrlParams() {
  try {
    yield call(storeParams);

    const address = getAddress();
    const selectedProvince = getSelectedProvince();
    const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(
      getConsolidatedBasicInfo
    );

    const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
      mortgageDetails: {
        loanType: basicInfo?.mortgageDetails?.loanType ?? undefined,
        unitNo: basicInfo?.mortgageDetails?.unitNo ?? address?.unitNo ?? undefined,
        streetNo:
          basicInfo?.mortgageDetails?.streetNo ??
          (address?.streetNo ? Number(address.streetNo) : undefined),
        streetName: basicInfo?.mortgageDetails?.streetName ?? address?.streetName ?? undefined,
        city: basicInfo?.mortgageDetails?.city ?? address?.city ?? undefined,
        province:
          (basicInfo?.mortgageDetails?.province as Province) ??
          (address?.province as Province) ??
          selectedProvince,
        postCode: basicInfo?.mortgageDetails?.postCode ?? address?.postalCode ?? undefined,
      },
    };
    yield put(saveBasicInfo(basicInfoRequest));
  } catch (error: any) {
    console.error("There was an error while parsing the url params", error);
  }
}

export function* initWhiteLabelConfigurations() {
  const whitelabelId: string = yield select(getWhitelabelId);
  yield call(fetchWhiteLabelConfigurationsOperation.saga, whitelabelId);
}

export function* initUser() {
  yield call(fetchAuthorizedUserOperation.saga);

  const isAuthed: boolean = yield select(getIsAuthorized);
  const userId: string = yield select(getUserId);

  if (isAuthed) {
    console.info("Authorised user: userId=" + userId);
    yield call(fetchUserApiOperation.saga);
  }

  if (userId) {
    console.info("fetch user basicInfo: userId=" + userId);
    yield call(fetchBasicInfoByUserIdOperation.saga, userId);
  }
}

export function* initLanguageAfterUserAndConfiguration(navigate: any) {
  const userLocale: LanguageType | undefined = yield select(getPreferredLanguage);
  const configDefaultLocale: LanguageType | undefined = yield select(getLanguage);

  let locale = getSearchParamLocale() || i18next.language || userLocale || LanguageType.EN_CA;

  // When the user first visits the app check the configuration default
  if (!localStorage.getItem("initLocale")) {
    localStorage.setItem("initLocale", "true");
    locale =
      getSearchParamLocale() ??
      userLocale ??
      configDefaultLocale ??
      i18next.language ??
      LanguageType.EN_CA;
  }
  yield call(onChangeLanguage, {
    payload: {navigate, locale},
  });
}

export function* onFetchProviderConfigs(navigate: any) {
  let mortgageProviderId: string = yield select(getMortgageProviderIdFromAppOrSelected);
  const defaultProviderId: string = yield select(getDefaultProviderId);
  const providerConfigResult: SagaResult<Configuration[]> = yield call(
    fetchProviderConfigurationsOperation.saga,
    mortgageProviderId || defaultProviderId
  );
  if (providerConfigResult instanceof ApiError) {
    console.error(
      "Redirect to error page, unable to load product provider configuration",
      providerConfigResult
    );
    yield call(navigate, AppRouteUrl.ERROR_PAGE_URL, {
      state: {
        message: t("errors.mortgageProviderFetchFailure"),
        status: providerConfigResult.status,
      },
    });
  }
}

export function* onFetchTheme(navigate: any) {
  const brokerCode: string = yield select(getBrokerCode);
  const themeResult: SagaResult<Theme> = yield call(fetchThemeOperation.saga, brokerCode);

  if (themeResult instanceof ApiError) {
    navigate(AppRouteUrl.ERROR_PAGE_URL, {
      state: {message: t("errors.themeFetchFailure"), status: themeResult.status},
    });
    throw new Error("Redirect to error page, due to theme not found, or missing name");
  }
  if (!themeResult?.name) {
    navigate(AppRouteUrl.ERROR_PAGE_URL, {
      state: {message: t("errors.themeInvalid")},
    });
    throw new Error("Redirect to error page, due to theme missing name");
  }
  return themeResult;
}

export function* onFetchWhiteLabel(navigate: any) {
  const whiteLabelName: string = yield select(getPlatform);
  const whiteLabelResult: SagaResult<WhiteLabelDetailsDto> = yield call(
    fetchWhitelabelOperation.saga,
    whiteLabelName
  );
  if (whiteLabelResult instanceof ApiError) {
    navigate(AppRouteUrl.ERROR_PAGE_URL, {
      state: {message: t("errors.whiteLabelFetchFailure"), status: 500},
    });
    throw new Error(
      `Redirect to error page, due to whiteLabel not found by name: ${whiteLabelName}` +
        whiteLabelResult
    );
  }
}

export function* onFetchAvailableProducts(navigate: any) {
  const whitelabelId: ProductSummary[] = yield select(getWhitelabelId);
  const providerIdFromAppOrSelected: string = yield select(getMortgageProviderIdFromAppOrSelected);
  const leadAssignmentProviderId = localStorage.getItem("leadAssignmentProviderId");
  const province =
    localStorage.getItem(QueryParamKeys.SELECTED_PROVINCE) ||
    localStorage.getItem(QueryParamKeys.PROVINCE);

  // Get mortgage providers and pass in the previously displayed brokerage id
  // if applicable, so the user can refresh the page and still see the same brokerage.
  const mortgageProvidersResult: SagaResult<WhiteLabelProductGroupDto> = yield call(
    fetchMortgageProvidersOperation.saga,
    whitelabelId,
    providerIdFromAppOrSelected || leadAssignmentProviderId,
    province
  );
  if (mortgageProvidersResult instanceof ApiError) {
    navigate(AppRouteUrl.ERROR_PAGE_URL, {
      state: {
        message: t("errors.mortgageProviderFetchFailure"),
        status: mortgageProvidersResult.status,
      },
    });
    throw new Error(
      "Redirect to error page, due to mortgage providers error" + mortgageProvidersResult
    );
  }
  if (
    !Boolean(mortgageProvidersResult.bank.products.length) &&
    !Boolean(mortgageProvidersResult.lender.products.length)
  ) {
    navigate(AppRouteUrl.ERROR_PAGE_URL, {
      state: {
        message: t("errors.mortgageProviderNotFound"),
      },
    });
    throw new Error("Redirect to error page, due no products");
  }
}

export function* pullDLABrokerId() {
  // Automatically select product provider if there is only one, this should only be set if it is not already set.
  // In the event that the sole provider changes from providerA to providerB, we must stick with providerA
  // because that's what the applicant signed up for.
  const leadAssignmentProviderId = localStorage.getItem("leadAssignmentProviderId");
  const products: ProductSummary[] = yield select(getProducts);
  const providerExists = products.some(
    (product) => product.financialInstitutionPublicId === leadAssignmentProviderId
  );
  if (!leadAssignmentProviderId || !providerExists) {
    const id = products.find(
      (product) => product.financialInstitutionType === FinancialInstitutionType.BROKERAGE
    )?.financialInstitutionPublicId;
    if (id) {
      localStorage.setItem("leadAssignmentProviderId", id);
      return id;
    }
  }
  return leadAssignmentProviderId;
}

export function* onAssignMortgageProvider() {
  const leadAssignmentProviderId: UUID = yield call(pullDLABrokerId);
  const appProviderOrSelected: UUID = yield select(getMortgageProviderIdFromAppOrSelected);

  if (appProviderOrSelected) {
    yield put(setDefaultProviderId(appProviderOrSelected));
    return;
  }

  if (leadAssignmentProviderId) {
    yield put(setDefaultProviderId(leadAssignmentProviderId));
    return;
  }

  let pickedProvider: UUID | undefined = yield select(pickProviderForDefaultProvider);
  if (pickedProvider) {
    yield put(setDefaultProviderId(pickedProvider));
  }
}

export function* initAppAssociatedFinancialInstitution(navigate: any) {
  const appMortgageProvider: UUID | undefined = yield select(getMortgageProviderIdFromApp);

  if (appMortgageProvider) {
    // Fetch the associated financial institution before fetching the products for display.
    const fetchAppFinancialInstResult: SagaResult<FinancialInstitutionBasicResponse> = yield call(
      fetchAppAssociatedFinancialInstitutionOperation.saga,
      appMortgageProvider
    );
    if (Operation.isError(fetchAppFinancialInstResult)) {
      navigate(AppRouteUrl.ERROR_PAGE_URL, {
        state: {
          message: t("errors.mortgageProviderFetchFailure"),
          status: fetchAppFinancialInstResult.status,
        },
      });
      throw new Error(
        `Redirect to error page, due to app associated financial institution not found: ${fetchAppFinancialInstResult}`
      );
    }
  }
}

export function* onInitialize({
  payload: {navigate},
}: WithPayload<Required<Pick<WithNavigate, "navigate">>>): any | void {
  yield call(initUser);
  yield call(initAppAssociatedFinancialInstitution, navigate);

  yield fork(storeUrlParams);

  try {
    // For now, these four calls depend on each other and must be done sequentially.
    yield call(onFetchTheme, navigate);
    yield call(onFetchWhiteLabel, navigate);
    yield call(onFetchAvailableProducts, navigate);
    yield call(onAssignMortgageProvider);

    yield all([call(initWhiteLabelConfigurations), call(initializeActiveRouteSequence)]);
  } catch (err: any) {
    console.error(err.message);
    return; // We don't want to continue if we are redirecting.
  }

  yield call(onFetchProviderConfigs, navigate);
  yield fork(initializeNavbar); // Depends on provider configurations, to determine if documents is complete.
  yield fork(initLanguageAfterUserAndConfiguration, navigate); // Call after user/white label configuration is fetched

  const hasUser: boolean = yield select(getHasUserAccount);

  if (!USER_ACCOUNT_NOT_REQUIRED_URLS.includes(window.location.pathname) && !hasUser) {
    console.info("Redirect to sign in page, due to user account not found");
    return navigate(AppRouteUrl.SIGN_IN);
  }
}

export default function* rootComponentSaga() {
  yield takeEvery(initialize, onInitialize);
}
