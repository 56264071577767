import {getFixedT} from "util/languageUtil";
import {SecondaryApplicantCompletePage as SecondaryApplicantCompletePageUI} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";

const fixedT = getFixedT("secondaryApplicantCompletePage");

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SecondaryApplicantCompletePage = ({containerRef}: Props) => {
  return (
    <SecondaryApplicantCompletePageUI
      containerRef={containerRef}
      header={fixedT("header")}
      body={fixedT("body")}
      navigationBar={<NavigationBar />}
    />
  );
};

export default SecondaryApplicantCompletePage;
