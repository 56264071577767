import {useEffect} from "react";
import {Routes as RoutesRouter, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import ErrorPage from "../ErrorPage/ErrorPage";
import {useSelector} from "react-redux";
import {getPlatform} from "store/selectors/theme";
import {
  AppRouteUrl,
  LanguageType,
  LoanType,
  QueryParamKeys,
} from "@pinch-financial/pinch-ui-components";
import {
  getAppId,
  getConsolidatedBasicInfo,
  getLoanTypeConsolidated,
} from "store/selectors/basicInfo";
import {getNextPageUrl} from "util/routeUtil";
import {
  getWhiteLabelFaviconUrl,
  getWhitelabelTitleEn,
  getWhitelabelTitleFr,
} from "store/selectors/whitelabel";
import ResendEmailPage from "pages/ResendEmailPage/ResendEmailPage";
import InviteSecondaryApplicantPage from "pages/InviteSecondaryApplicantPage/InviteSecondaryApplicantPage";
import SignInPage from "pages/SignInPage/SignInPage";
import SecondarySignUpPage from "pages/SecondarySignUpPage/SecondarySignUpPage";
import SetPasswordPage from "pages/SetPasswordPage/SetPasswordPage";
import SignUpPage from "pages/SignUpPage/SignUpPage";
import AddressInfoPage from "pages/AddressInfoPage/AddressInfoPage";
import AuthorizationCodeCallback from "pages/AuthorizationCodeCallback/AuthorizationCodeCallback";
import UnresolvedAVMPage from "pages/UnresolvedAVMPage/UnresolvedAVMPage";
import BanksAndFlinksConnectPage from "pages/BanksAndFlinksPage/BanksAndFlinksPage";
import WelcomePage from "pages/WelcomePage/WelcomePage";
import MortgageTypePage from "pages/MortgageTypePage/MortgageTypePage";
import PurchaseTimeframePage from "pages/PurchaseTimeframePage/PurchaseTimeframePage";
import SelfReportedCreditScorePage from "pages/SelfReportedCreditScorePage/SelfReportedCreditScorePage";
import BankOrBrokerPage from "pages/BankOrBrokerPage/BankOrBrokerPage";
import DeclinedPage from "pages/DeclinedPage/DeclinedPage";
import DisclosurePage from "pages/DisclosurePage/DisclosurePage";
import PropertyAddressInfoPage from "pages/PropertyAddressInfoPage/PropertyAddressInfoPage";
import MortgageInfoPage from "pages/MortgageInfoPage/MortgageInfoPage";
import EmploymentInfoPage from "pages/EmploymentInfoPage/EmploymentInfoPage";
import DocumentUploadPage from "pages/DocumentUploadPage/DocumentUploadPage";
import SelfReportedAssetsPage from "pages/SelfReportedAssetsPage/SelfReportedAssetsPage";
import SelfReportedLiabilitiesPage from "pages/SelfReportedLiabilitiesPage/SelfReportedLiabilitiesPage";
import ProductSelectPage from "pages/ProductSelectPage/ProductSelectPage";
import SuccessPage from "pages/SuccessPage/SuccessPage";
import EquifaxPage from "pages/EquifaxPage/EquifaxPage";
import NoLendersPage from "pages/NoLendersPage/NoLendersPage";
import CalculateQualificationPage from "pages/CalculateQualificationPage/CalculateQualificationPage";
import SecondaryApplicantCompletePage from "pages/SecondaryApplicantCompletePage/SecondaryApplicantCompletePage";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
  getPreviousUrlFromHistory,
} from "store/selectors/navigationController";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {getAuthUserEmail} from "store/selectors/auth";
import ProvinceSelectPage from "pages/ProvinceSelectPage/ProvinceSelectPage";
import {getHasUserAccount} from "store/selectors/user";
import {getIsProvinceSelectPageActive} from "store/selectors/configurations";
import i18next from "i18next";
import {equalIgnoreCase} from "components/utils/stringUtil";
import ProductFetchTimeoutPage from "pages/ProductFetchTimeoutPage/ProductFetchTimeoutPage";

const Routes = () => {
  const navigate = useNavigate();
  const favicon = useSelector(getWhiteLabelFaviconUrl);
  const location = useLocation();
  const whiteLabelTitleFr = useSelector(getWhitelabelTitleFr);
  const whiteLabelTitleEn = useSelector(getWhitelabelTitleEn);
  const activeRouteSequence = useSelector(getActiveRouteSequence);
  const basicInfo = useSelector(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked = useSelector(getHasNavbarAssetLiabilitiesStepClicked);
  const hasUserAccount = useSelector(getHasUserAccount);
  const isProvinceSelectPageEnabled = useSelector(getIsProvinceSelectPageActive);
  const email = useSelector(getAuthUserEmail);
  const platform = useSelector(getPlatform);
  const appId = useSelector(getAppId);
  const loanType = useSelector(getLoanTypeConsolidated);
  const previousPageUrl = useSelector(getPreviousUrlFromHistory);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page: location.pathname + location.search,
      page_search: location.search,
      dimension1: platform || null,
      dimension2: email || null,
      dimension3: appId || null,
    });
  }, [location.pathname, email, appId]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: "auto"});
  }, [location]);

  const onNextPage = (currentUrl: AppRouteUrl) => {
    return () => {
      navigate(
        getNextPageUrl(
          currentUrl,
          activeRouteSequence,
          basicInfo,
          hasNavbarAssetLiabilitiesStepClicked
        )
      );
    };
  };

  const onPrevPage = (currentUrl: AppRouteUrl) => {
    return () => {
      if (
        previousPageUrl === AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO &&
        loanType &&
        equalIgnoreCase(LoanType.PREAPPROVAL, loanType)
      ) {
        navigate(-2);
      } else {
        navigate(-1);
      }
    };
  };
  return (
    <>
      <Helmet>
        <title>
          {i18next.language === LanguageType.EN_CA ? whiteLabelTitleEn : whiteLabelTitleFr}
        </title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>

      <RoutesRouter location={location} key={location.pathname}>
        <Route path={AppRouteUrl.ERROR_PAGE_URL} element={<ErrorPage />} />
        <Route
          path={AppRouteUrl.LANDING_PAGE_URL}
          element={
            <WelcomePage
              onNextPage={() => {
                const province = localStorage.getItem(QueryParamKeys.PROVINCE);
                if (!province && !hasUserAccount && isProvinceSelectPageEnabled) {
                  navigate(AppRouteUrl.PROVINCE_SELECT_PAGE_URL);
                } else {
                  onNextPage(AppRouteUrl.LANDING_PAGE_URL)();
                }
              }}
            />
          }
        />
        {isProvinceSelectPageEnabled && (
          <Route
            path={AppRouteUrl.PROVINCE_SELECT_PAGE_URL}
            element={
              <ProvinceSelectPage
                onPrevPage={onPrevPage(AppRouteUrl.PROVINCE_SELECT_PAGE_URL)}
                onNextPage={onNextPage(AppRouteUrl.LANDING_PAGE_URL)}
              />
            }
          />
        )}
        <Route
          path={AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE}
          element={
            <MortgageTypePage
              onPrevPage={onPrevPage(AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE)}
            />
          }
        />
        <Route
          path={AppRouteUrl.PURCHASE_TIMEFRAME_URL}
          element={
            <PurchaseTimeframePage
              onPrevPage={onPrevPage(AppRouteUrl.PURCHASE_TIMEFRAME_URL)}
              onNextPage={onNextPage(AppRouteUrl.PURCHASE_TIMEFRAME_URL)}
            />
          }
        />
        <Route
          path={AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT}
          element={
            <SelfReportedCreditScorePage
              onPrevPage={onPrevPage(AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT)}
              onNextPage={onNextPage(AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT)}
            />
          }
        />

        <Route
          path={AppRouteUrl.BANK_OR_BROKER}
          element={<BankOrBrokerPage onPrevPage={onPrevPage(AppRouteUrl.BANK_OR_BROKER)} />}
        />

        <Route
          path={AppRouteUrl.SIGN_UP}
          element={<SignUpPage onPrevPage={onPrevPage(AppRouteUrl.SIGN_UP)} />}
        />
        <Route path={AppRouteUrl.DECLINED_TERMS_URL} element={<DeclinedPage />} />
        <Route path={AppRouteUrl.DISCLOSURE_URL} element={<DisclosurePage />} />
        <Route
          path={AppRouteUrl.INVITE_SECONDARY_APPLICANT}
          element={
            <InviteSecondaryApplicantPage
              onPrevPage={onPrevPage(AppRouteUrl.INVITE_SECONDARY_APPLICANT)}
              onNextPage={onNextPage(AppRouteUrl.INVITE_SECONDARY_APPLICANT)}
            />
          }
        />
        <Route
          path={AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO}
          element={
            <PropertyAddressInfoPage
              onPrevPage={onPrevPage(AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO)}
              onNextPage={onNextPage(AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO)}
            />
          }
        />

        <Route
          path={AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION}
          element={
            <MortgageInfoPage
              onPrevPage={onPrevPage(AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION)}
              onNextPage={onNextPage(AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION)}
            />
          }
        />
        <Route
          path={AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT}
          element={
            <EmploymentInfoPage
              onPrevPage={onPrevPage(AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT)}
              onNextPage={onNextPage(AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT)}
            />
          }
        />

        <Route
          path={AppRouteUrl.BASIC_INFO_URL_ADDRESS}
          element={<AddressInfoPage onPrevPage={onPrevPage(AppRouteUrl.BASIC_INFO_URL_ADDRESS)} />}
        />

        <Route
          path={AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS}
          element={
            <DocumentUploadPage
              onPrevPage={onPrevPage(AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS)}
              onNextPage={onNextPage(AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS)}
            />
          }
        />
        <Route
          path={AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS}
          element={
            <SelfReportedAssetsPage
              onPrevPage={onPrevPage(AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS)}
              onNextPage={onNextPage(AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS)}
            />
          }
        />
        <Route
          path={AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES}
          element={
            <SelfReportedLiabilitiesPage
              onPrevPage={onPrevPage(AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES)}
              onNextPage={onNextPage(AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES)}
            />
          }
        />

        <Route
          path={AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO}
          element={
            <BanksAndFlinksConnectPage
              onPrevPage={onPrevPage(AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO)}
              onNextPage={onNextPage(AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO)}
            />
          }
        />
        <Route
          path={AppRouteUrl.BASIC_INFO_URL_SIN_FORM}
          element={
            <EquifaxPage
              onPrevPage={onPrevPage(AppRouteUrl.BASIC_INFO_URL_SIN_FORM)}
              onNextPage={onNextPage(AppRouteUrl.BASIC_INFO_URL_SIN_FORM)}
            />
          }
        />
        <Route
          path={AppRouteUrl.JOINT_COMPLETED_URL}
          element={<SecondaryApplicantCompletePage />}
        />
        <Route path={AppRouteUrl.DETAILS_COMPLETE_URL} element={<CalculateQualificationPage />} />
        <Route path={AppRouteUrl.SELECT_LENDERS_URL} element={<ProductSelectPage />} />
        <Route
          path={AppRouteUrl.NO_LENDERS_URL}
          element={<NoLendersPage onPrevPage={() => navigate(-2)} />}
        />
        <Route
          path={AppRouteUrl.PRODUCT_FETCH_TIMEOUT_URL}
          element={
            <ProductFetchTimeoutPage onPrevPage={() => navigate(AppRouteUrl.SELECT_LENDERS_URL)} />
          }
        />
        <Route path={AppRouteUrl.APPLICATION_COMPLETE_URL} element={<SuccessPage />} />

        <Route
          path={AppRouteUrl.UNRESOLVED_AVM_URL}
          element={
            <UnresolvedAVMPage
              onPrevPage={onPrevPage(AppRouteUrl.UNRESOLVED_AVM_URL)}
              onNextPage={onNextPage(AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO)}
            />
          }
        />

        <Route path={AppRouteUrl.SECONDARY_SIGNUP} element={<SecondarySignUpPage />} />
        <Route path={AppRouteUrl.SIGN_IN} element={<SignInPage />} />
        <Route path={AppRouteUrl.LOGIN_CALLBACK} element={<AuthorizationCodeCallback />} />
        <Route path={AppRouteUrl.RESEND_EMAIL_URL} element={<ResendEmailPage />} />
        <Route path={AppRouteUrl.RESET_PASSWORD_URL} element={<SetPasswordPage />} />
        <Route path={AppRouteUrl.ACTIVATE_ACCOUNT_URL} element={<SetPasswordPage />} />

        <Route path="*" element={<Navigate to={AppRouteUrl.LANDING_PAGE_URL} />} />
      </RoutesRouter>
      <NavigationBar />
    </>
  );
};

export default Routes;
