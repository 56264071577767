import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {StepStatusRecord} from "types/NavigationController";
import {NavStep} from "@pinch-financial/pinch-ui-components";
import {RouteUrlValue} from "types/routeUrlValue";
import {WithNavigate} from "types/ui";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {PageSequence} from "types/pagesequence";

const namespace = "NavigationBar";

export interface HandleNewUrl {
  step: NavStep;
  url: RouteUrlValue;
}

export interface NavigateStep extends WithNavigate {
  step?: NavStep;
  url: RouteUrlValue;
}

export interface StoreStepStatusChange {
  step: NavStep;
  hidden: boolean;
  optional: boolean;
  completed: boolean;
  visited: boolean;
}
export const initializeStatus = createAction<StepStatusRecord>(
  actionTypeOf(namespace, "initializeStatus")
);

export const storeUrl = createAction<RouteUrlValue>(actionTypeOf(namespace, "storeUrl"));

export const storeNextUrl = createAction<RouteUrlValue>(actionTypeOf(namespace, "storeNextUrl"));

export const calculateStepStatusChange = createAction<NavStep>(
  actionTypeOf(namespace, "calculateStepStatusChange")
);

export const storeStepStatusChange = createAction<StoreStepStatusChange>(
  actionTypeOf(namespace, "storeStepStatusChange")
);

export const navigateStep = createAction<NavigateStep>(actionTypeOf(namespace, "navigateStep"));

export const storeCurrentStep = createAction<NavStep>(actionTypeOf(namespace, "storeCurrentStep"));

export const handleNewUrl = createAction<HandleNewUrl>(actionTypeOf(namespace, "handleNewUrl"));

export const storeNavigationError = createAction<string>(
  actionTypeOf(namespace, "storeNavigationError")
);

export const removeNavigationError = createAction<void>(
  actionTypeOf(namespace, "removeNavigationError")
);

export const initializeActiveRouteSequence = createAction<void>(
  actionTypeOf(namespace, "initializeActiveRouteSequence")
);

export const setActiveRouteSequence = createAction<AppRouteUrl[]>(
  actionTypeOf(namespace, "setActiveRouteSequence")
);

export const setActivePageSequence = createAction<PageSequence[]>(
  actionTypeOf(namespace, "setActivePageSequence")
);

export const removeRouteFromSequence = createAction<AppRouteUrl>(
  actionTypeOf(namespace, "removeRouteFromSequence")
);
export const storeNavbarClickedStep = createAction<NavStep>(
  actionTypeOf(namespace, "storeNavbarClickedStep")
);

export const popUrlFromHistory = createAction(actionTypeOf(namespace, "popUrlFromHistory"));

export const navigateBackLastPage = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBackLastPage")
);

export const setSlideDirection = createAction<-1 | 1>(actionTypeOf(namespace, "setSlideDirection"));

export const setSkipAnimation = createAction<boolean>(actionTypeOf(namespace, "setSkipAnimation"));
