import {Breakpoints} from "@mui/material/styles";
import debounce from "@mui/material/utils/debounce";
import React, {useEffect} from "react";
const DEBOUNCE_WAIT_MS = 100;

const useOnResize = (breakpoints: Breakpoints) => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  const debouncedHandle = debounce(handleResize, DEBOUNCE_WAIT_MS);

  useEffect(() => {
    window.addEventListener("resize", debouncedHandle);
    return () => {
      window.removeEventListener("resize", debouncedHandle);
    };
  });

  return {dimensions, isScreenMobileWidth: dimensions.width < breakpoints.values.laptop};
};

export default useOnResize;
