import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  getConsolidatedBasicInfo,
  getContactInfo,
  getHasCreditSuccess,
  getIsLocked,
} from "store/selectors/basicInfo";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {submitAddressInfo} from "./AddressInfoPage.action";
import {getFixedT} from "util/languageUtil";
import {
  AppRouteUrl,
  LanguageType,
  OnNextPage,
  PageRef,
  TrackingEventType,
} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import {Province} from "types/enums/province";
import {
  AddressInfoPageFormData,
  AddressInfoPage as AddressInfoPageUI,
  OwnershipStatus,
  YearsAtAddressLength,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import i18next from "i18next";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {useEffect, useRef, useState} from "react";
import SaveButton from "components/SaveButton/SaveButton";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";

const fixedT = getFixedT("addressInfoPage");

interface Props {
  className?: string;
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const AddressInfoPage = ({onPrevPage = () => {}, className = "", containerRef}: Props) => {
  const pageRef = useRef<PageRef>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const basicInfo = useSelector(getConsolidatedBasicInfo);
  const hasCreditSuccess = useSelector(getHasCreditSuccess);
  const locked = useSelector(getIsLocked);
  const {isPending, failure} = useSelector(getOperationResult)(OperationType.submitAddressInfo);
  const previousURL = useSelector(getPreviousUrlFromHistory);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const {email} = useSelector(getContactInfo);
  const [fieldError, setFieldError] = useState<string>();

  const provinceOptions = (typeVal: Province) => {
    return fixedT(`provinces.${typeVal}`);
  };

  const timeAtAddressOptions = (typeVal: YearsAtAddressLength) => {
    return fixedT("timeAtAddress", {context: typeVal});
  };

  const onSubmit: OnNextPage<AddressInfoPageFormData> = (values, event, shouldStay) => {
    trackEvent(TrackingEventType.addressInfoClickNextButton);
    setFieldError("");
    dispatch(
      submitAddressInfo({
        data: {...values} as AddressInfoPageFormData,
        navigate,
        shouldStay,
      })
    );
  };

  const onBack = () => {
    trackEvent(TrackingEventType.addressInfoClickBackButton);
    onPrevPage?.();
  };

  useEffect(() => {
    if (failure) {
      console.error(`Operation error found: ${failure}`);
    }
  }, [failure]);

  return (
    <AddressInfoPageUI
      containerRef={containerRef}
      ref={pageRef}
      onPrevPage={onBack}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      backButtonText={i18next.t("backButton")}
      nextButtonText={i18next.t("submitButton")}
      header={fixedT("header")}
      description={fixedT("description")}
      tipHeader={fixedT("tipHeader")}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody", {email: email})}} />}
      iconAlt={fixedT("iconAlt")}
      pageError={fieldError}
      isPending={isPending}
      locked={locked}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      lockCurrentAddress={hasCreditSuccess}
      googlePlacesApiKey={window._env_.REACT_APP_GOOGLE_PLACES_API_KEY}
      labelStreetNumber={fixedT("labelStreetNumber")}
      labelSuiteNumber={fixedT("labelSuiteNumber")}
      labelStreetName={fixedT("labelStreetName")}
      labelCity={fixedT("labelCity")}
      labelProvince={fixedT("labelProvince")}
      labelPostalCode={fixedT("labelPostalCode")}
      labelOwnershipStatus={fixedT("labelOwnershipStatus")}
      ownershipStatusRenter={fixedT("ownershipStatus", {context: OwnershipStatus.RENTER})}
      ownershipStatusOwner={fixedT("ownershipStatus", {context: OwnershipStatus.OWNER})}
      labelTimeAtAddress={fixedT("labelTimeAtAddress")}
      previousAddressMessage={fixedT("previousAddressMessage")}
      labelGoogleAddressCurrent={fixedT("fields.googleAddressCurrent.label")}
      placeholderGoogleAddressCurrent={fixedT("fields.googleAddressCurrent.placeholder")}
      labelGoogleAddressPrevious={fixedT("fields.googleAddressPrevious.label")}
      placeholderGoogleAddressPrevious={fixedT("fields.googleAddressPrevious.placeholder")}
      // Default values
      timeAtAddress={basicInfo?.timeAtAddress}
      ownershipStatus={basicInfo?.ownershipStatus}
      residentialAddressUnitNo={basicInfo?.residentialAddress?.unitNo}
      residentialAddressStreetNo={basicInfo?.residentialAddress?.streetNo}
      residentialAddressStreetName={basicInfo?.residentialAddress?.streetName}
      residentialAddressCity={basicInfo?.residentialAddress?.city}
      residentialAddressProvince={basicInfo?.residentialAddress?.province}
      residentialAddressPostCode={basicInfo?.residentialAddress?.postCode}
      previousResidentialAddressUnitNo={basicInfo?.previousResidentialAddress?.unitNo}
      previousResidentialAddressStreetNo={basicInfo?.previousResidentialAddress?.streetNo}
      previousResidentialAddressStreetName={basicInfo?.previousResidentialAddress?.streetName}
      previousResidentialAddressCity={basicInfo?.previousResidentialAddress?.city}
      previousResidentialAddressProvince={basicInfo?.previousResidentialAddress?.province}
      previousResidentialAddressPostCode={basicInfo?.previousResidentialAddress?.postCode}
      timeAtAddressOptions={timeAtAddressOptions}
      provinceOptions={provinceOptions}
      missingCurrentStreetNo={fixedT("messages.missing", {
        context: "currentStreetNo",
      })}
      missingCurrentStreetName={fixedT("messages.missing", {
        context: "currentStreetName",
      })}
      missingCurrentCity={fixedT("messages.missing", {
        context: "currentCity",
      })}
      missingCurrentProvince={fixedT("messages.missing", {
        context: "currentProvince",
      })}
      missingCurrentPostCode={fixedT("messages.missing", {
        context: "currentPostCode",
      })}
      missingPreviousStreetNo={fixedT("messages.missing", {
        context: "previousStreetNo",
      })}
      missingPreviousStreetName={fixedT("messages.missing", {
        context: "previousStreetName",
      })}
      missingPreviousCity={fixedT("messages.missing", {
        context: "previousCity",
      })}
      missingPreviousProvince={fixedT("messages.missing", {
        context: "previousProvince",
      })}
      missingPreviousPostCode={fixedT("messages.missing", {
        context: "previousPostCode",
      })}
      missingTimeAtAddress={fixedT("messages.missing", {
        context: "timeAtAddress",
      })}
      missingOwnershipStatus={fixedT("messages.missing", {
        context: "ownershipStatus",
      })}
      invalidPostCode={fixedT("messages.invalid", {
        context: "postCode",
      })}
      invalidPreviousPostCode={fixedT("messages.invalid", {
        context: "postCode",
      })}
      isFrench={i18next.language === LanguageType.FR_CA}
    />
  );
};

export default AddressInfoPage;
