import {
  PropertyAddressInfoPageFormData,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "PropertyAddressInfoPage";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const submitPropertyInformation = createAction<
  WithNavigatePayload<PropertyAddressInfoPageFormData>
>(actionTypeOf(namespace, "submit"));

export const navigatePreviousStep = createAction<WithNavigate>(
  actionTypeOf(namespace, "navigateBack")
);
