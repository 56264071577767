import {useEffect, useRef, useState} from "react";
import i18next from "i18next";
import {Province} from "types/enums/province";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  getContactInfo,
  getIsLocked,
  getLoanTypeConsolidated,
  getMortgageDetailsOrEmpty,
} from "store/selectors/basicInfo";
import {submitPropertyInformation, initialize} from "./PropertyAddressInfoPage.action";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {AppRouteUrl, LoanType, OnNextPage, PageRef} from "@pinch-financial/pinch-ui-components";

import {moneyOrZero} from "components/utils/moneyUtil";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {
  PropertyAddressInfoPage as PropertyAddressInfoPageUI,
  PropertyAddressInfoPageFormData,
  PropertyType,
  LanguageType,
} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {getFixedT} from "util/languageUtil";
import SaveButton from "components/SaveButton/SaveButton";
import {getPreviousUrlFromHistory} from "store/selectors/navigationController";

const fixedT = getFixedT("propertyAddressInfoPage");

export const LOAN_TYPES: Record<
  any,
  {trackingEventBack: TrackingEventType; trackingEventNext: TrackingEventType}
> = {
  [LoanType.NEW_PURCHASE]: {
    trackingEventBack: TrackingEventType.propertyInformationNewPurchaseClickBack,
    trackingEventNext: TrackingEventType.propertyInformationNewPurchaseClickNext,
  },
  [LoanType.RENEWAL]: {
    trackingEventBack: TrackingEventType.propertyInformationRenewalClickBack,
    trackingEventNext: TrackingEventType.propertyInformationRenewalClickNext,
  },
};

interface Props {
  onPrevPage?: () => any;
  onNextPage?: () => any;
  className?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PropertyAddressInfoPage = ({
  onPrevPage = () => {},
  onNextPage = () => {},
  className = "",
  containerRef,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageRef = useRef<PageRef>(null);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const {email} = useSelector(getContactInfo);
  const locked = useSelector(getIsLocked);
  const mortgageDetails = useSelector(getMortgageDetailsOrEmpty);
  const loanType = useSelector<any, LoanType | undefined>(getLoanTypeConsolidated);
  const previousURL = useSelector(getPreviousUrlFromHistory);
  const {isPending, failure} = useSelector(getOperationResult)(
    OperationType.submitPropertyInformation
  );
  const [fieldError, setFieldError] = useState<string>();

  const provinceOptions = (province: Province) => {
    return fixedT(`form.provinces.${province}`);
  };

  const onSubmit: OnNextPage<PropertyAddressInfoPageFormData> = (values, event, shouldStay) => {
    if (loanType) {
      trackEvent(LOAN_TYPES[loanType].trackingEventNext);
    }

    setFieldError("");
    dispatch(
      submitPropertyInformation({
        navigate,
        data: {...values},
        shouldStay,
        onNextPage,
      })
    );
  };

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  useEffect(() => {
    // We never had a translation for this so
    // I'm not comfortable with displaying this error in the UI
    if (failure) {
      console.error(`Operation error found: ${failure}`);
    }
  }, [failure]);

  return (
    <PropertyAddressInfoPageUI
      ref={pageRef}
      containerRef={containerRef}
      onPrevPage={onPrevPage}
      onNextPage={onSubmit}
      isMobileKeyboardOpen={isKeyboardOpen}
      navigationBar={<NavigationBar pageRef={pageRef} />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" pageRef={pageRef} />}
      backButtonText={i18next.t("backButton")}
      nextButtonText={fixedT("form.submit")}
      header={
        LoanType.NEW_PURCHASE === loanType ? fixedT("headerNewPurchase") : fixedT("headerRenewal")
      }
      description={
        LoanType.NEW_PURCHASE === loanType
          ? fixedT("descriptionNewPurchase")
          : fixedT("descriptionRenewal")
      }
      tipHeader={fixedT("tipHeader")}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody", {email: email})}} />}
      pageError={fieldError}
      isPending={isPending}
      locked={locked}
      validateOnRender={previousURL === AppRouteUrl.SELECT_LENDERS_URL}
      googlePlacesApiKey={window._env_.REACT_APP_GOOGLE_PLACES_API_KEY}
      labelStreetNumber={fixedT("form.streetNumber")}
      labelSuiteNumber={fixedT("form.suiteNumber")}
      labelStreetName={fixedT("form.streetName")}
      labelCity={fixedT("form.city")}
      labelProvince={fixedT("form.province")}
      labelPostalCode={fixedT("form.postalCode")}
      labelPropertyType={fixedT("form.propertyType")}
      propertyTypeOptionsFreehold={fixedT("form.propertyTypeOptions", {
        context: PropertyType.FREEHOLD,
      })}
      propertyTypeOptionsCondo={fixedT("form.propertyTypeOptions", {context: PropertyType.CONDO})}
      labelMonthlyCondoFees={fixedT("form.monthlyCondoFees")}
      labelAnnualPropertyTaxes={fixedT("form.annualPropertyTaxes")}
      labelGoogleAddress={fixedT("form.fields.googleAddress.label")}
      placeholderGoogleAddress={fixedT("form.fields.googleAddress.placeholder")}
      labelMonthlyHeatingCosts={fixedT("form.monthlyHeatingCosts")}
      labelSquareFootage={fixedT("form.squareFootage")}
      // Default values
      mortgageDetails={mortgageDetails}
      mortgageDetailsCondoFees={moneyOrZero(mortgageDetails?.condoFees?.amount)}
      mortgageDetailsHeatingCosts={moneyOrZero(mortgageDetails?.heatingCosts?.amount)}
      mortgageDetailsPropertyTaxes={moneyOrZero(mortgageDetails?.propertyTaxes?.amount)}
      mortgageDetailsPropertyType={mortgageDetails?.propertyType}
      mortgageDetailsSquareFootage={mortgageDetails?.squareFootage}
      mortgageDetailsStreetNo={mortgageDetails?.streetNo ?? ""}
      mortgageDetailsUnitNo={mortgageDetails?.unitNo ?? ""}
      mortgageDetailsStreetName={mortgageDetails?.streetName ?? ""}
      mortgageDetailsCity={mortgageDetails?.city ?? ""}
      mortgageDetailsProvince={mortgageDetails?.province ?? Province.NULL}
      mortgageDetailsPostCode={mortgageDetails?.postCode ?? ""}
      provinceOptions={provinceOptions}
      selectedLoanType={loanType}
      // Schema
      cityMissingMessage={fixedT("messages.cityMissing")}
      streetNameMissingMessage={fixedT("messages.streetNameMissing")}
      streetNoMissingMessage={fixedT("messages.streetNoMissing")}
      provinceMissingMessage={fixedT("messages.provinceMissing")}
      propertyTypeMissingMessage={fixedT("messages.propertyTypeMissing")}
      invalidPostalCodeMessage={fixedT("messages.invalidPostalCode")}
      postalCodeMissingMessage={fixedT("messages.postalCodeMissing")}
      squareFootageMissingMessage={fixedT("messages.squareFootageMissing")}
      condoFeeMissingMessage={fixedT("messages.condoFeeMissing")}
      heatingCostZeroOrNegativeMessage={fixedT("messages.heatingCostZeroOrNegative")}
      propertyTaxZeroOrNegativeMessage={fixedT("messages.propertyTaxZeroOrNegative")}
      isFrench={i18next.language === LanguageType.FR_CA}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default PropertyAddressInfoPage;
