import {Theme} from "types/theme";

function cssOf(theme: Theme): string {
  return `:root {
      ${css("--primary-thematic-colour-100", theme.primaryColor)}
      ${css("--secondary-thematic-colour-100", theme.secondaryColor)}
      ${css("--tertiary-thematic-colour-100", theme.tertiaryColor)}
      ${css("--font-family", theme.fontFamily)}
    }`;
}

function css(key: string, val?: string) {
  return val ? `${key}: ${val};` : "";
}

export const applyThemeToDocument = (theme: Theme): void => {
  if (!theme) {
    return;
  }
  const styleElement = document.createElement("style");
  styleElement.textContent = cssOf(theme);
  document.head.append(styleElement);

  const font = theme.fontUrl;
  if (font) {
    const linkElement = document.createElement("link");
    linkElement.setAttribute("href", font);
    linkElement.setAttribute("rel", "stylesheet");
    document.head.appendChild(linkElement);
  }
};
