import {datadogRum} from "@datadog/browser-rum";

if (window._env_.REACT_APP_DATADOG_RUM_ENABLED === "true") {
  datadogRum.init({
    applicationId: "80df74a8-5a3a-4891-ab63-2bbc77140456", // We're going to use app 1.0s applicationId.
    clientToken: "pub8f448998aacabaed5946592b2ccedee7",
    site: "us3.datadoghq.com",
    service: "pinch-application2-ui",
    env: window._env_.REACT_APP_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: "BUILD_NUMBER",
    sampleRate: Number(window._env_.REACT_APP_RUM_SAMPLE_RATE),
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}
datadogRum.startSessionReplayRecording();

export default datadogRum;
