import ListItemIcon from "@mui/material/ListItemIcon";
import {faPhone, faMailBulk} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItem,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import {signOut} from "store/actions/auth.action";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import {navigateStep} from "../NavigationBar.action";
import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getContactInfo} from "store/selectors/consolidatedSelectors";
import {getHasUserAccount} from "store/selectors/user";
import {useTranslation} from "react-i18next";
import {LanguageType} from "types/enums/languageType";
import {allLanguageTypes, LanguageTypeCaption, toLanguageTypeOrDefault} from "util/languageUtil";
import {changeLanguage} from "components/NavigationBar/NavigationBarActionSection/NavigationBarActionSection.action";
import i18next from "i18next";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {RoutesRequireFinancialInstitutionByAppOrAuthId} from "util/routeUtil";

export default function NavigationBarActionSection() {
  const {t} = useTranslation();

  const availableLanguages = [...(allLanguageTypes as LanguageType[])];
  const [contactToggleOpen, setContactToggleOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const hasUserAccount = useSelector(getHasUserAccount);
  const contactInfo = useSelector(getContactInfo);
  const {isDone: fetchFinancialInstDone} = useSelector(getOperationResult)(
    OperationType.fetchAppAssociatedFinancialInstitutionByAppOrAuthId
  );

  const providerReady = RoutesRequireFinancialInstitutionByAppOrAuthId.includes(location.pathname)
    ? fetchFinancialInstDone
    : true;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <List>
      {hasUserAccount ? (
        <ListItemButton
          onClick={() => {
            dispatch(signOut());
          }}
        >
          <ListItemText primary={t("navigationBar.buttons.signOut")} />
        </ListItemButton>
      ) : (
        <ListItemButton
          onClick={() => {
            trackEvent(TrackingEventType.clickLogin);
            dispatch(
              navigateStep({
                url: AppRouteUrl.SIGN_IN,
                navigate,
              })
            );
          }}
        >
          <ListItemText primary={t("navigationBar.buttons.signIn")} />
        </ListItemButton>
      )}
      {providerReady && contactInfo && (
        <>
          <ListItemButton onClick={() => setContactToggleOpen(!contactToggleOpen)}>
            <ListItemText primary={t("navigationBar.toggle.contactToggle")} />
            {contactToggleOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={contactToggleOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {contactInfo?.phone && (
                <ListItem sx={{gap: "10px"}}>
                  <ListItemIcon sx={{minWidth: "unset"}}>
                    <FontAwesomeIcon icon={faPhone} />
                  </ListItemIcon>
                  <ListItemText
                    primary={contactInfo?.phone}
                    sx={{
                      wordWrap: "break-word",
                    }}
                  />
                </ListItem>
              )}
              {contactInfo?.email && (
                <ListItem sx={{gap: "10px"}}>
                  <ListItemIcon sx={{minWidth: "unset"}}>
                    <FontAwesomeIcon icon={faMailBulk} />
                  </ListItemIcon>
                  <Tooltip title={contactInfo?.email}>
                    <ListItemText
                      primary={t("navigationBar.emailUs")}
                      tabIndex={contactToggleOpen ? 0 : -1}
                      onKeyDown={(e) =>
                        e.key === "Enter" && window.open(`mailto:${contactInfo?.email}`, "_blank")
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`mailto:${contactInfo?.email}`, "_blank");
                      }}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </ListItem>
              )}
            </List>
          </Collapse>
        </>
      )}

      <>
        <Menu
          sx={{zIndex: 1501}}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          onClose={() => setAnchorEl(null)}
        >
          {availableLanguages.map((language: LanguageType, index: number) => (
            <MenuItem
              key={`language-toggle-${index}`}
              selected={i18next.language === language}
              onClick={() => {
                setAnchorEl(null);
                dispatch(changeLanguage({locale: language, navigate}));
              }}
            >
              {
                t("navigationBar.languageSelector", {
                  context: LanguageTypeCaption[language],
                })!
              }
            </MenuItem>
          ))}
        </Menu>
        <ListItemButton
          onClick={(e) => {
            e.preventDefault();
            e.currentTarget.focus();
            setAnchorEl(e.currentTarget);
          }}
        >
          <ListItemText
            primary={
              t("navigationBar.languageSelector", {
                context: LanguageTypeCaption[toLanguageTypeOrDefault(i18next.language)],
              })!
            }
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </>
    </List>
  );
}
