export function nextItem<T = any>(array: T[], targetItem: T): T | undefined {
  if (!array || array.length === 0) {
    return undefined;
  }
  const index = array.indexOf(targetItem);
  if (index === -1) {
    return undefined;
  }
  if (index === array.length - 1) {
    return undefined;
  }
  return array[index + 1];
}
