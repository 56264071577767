import {RegisterSecondaryUserPayload, UUID} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";

const namespace = "SecondarySignUpPage";

export const registerSecondaryUser = createAction<RegisterSecondaryUserPayload>(
  actionTypeOf(namespace, "registerSecondaryUser")
);

export const fetchSelectedProvider = createAction<{appId: UUID}>(
  actionTypeOf(namespace, "fetchSelectedProvider")
);

export const initialize = createAction<{appId: UUID}>(actionTypeOf(namespace, "initialize"));
