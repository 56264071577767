import {
  SelfReportedAssetsPageFormData,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {WithNavigate} from "types/ui";

const namespace = "SelfReportedAssetsPage";

export interface OtherEntry {
  amount?: number;
  description?: string;
}

export const submit = createAction<WithNavigatePayload<SelfReportedAssetsPageFormData>>(
  actionTypeOf(namespace, "submit")
);

export const acceptMissingAssetsDataWarning = createAction<boolean>(
  actionTypeOf(namespace, "acceptMissingAssetsDataWarning")
);

export const openUserAcceptedAssetsDataWarningModal = createAction<boolean>(
  actionTypeOf(namespace, "openUserAcceptedAssetsDataWarningModal")
);
