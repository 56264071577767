import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getContactInfo, getIsLocked, getLoanTypeConsolidated} from "store/selectors/basicInfo";
import {LoanType} from "@pinch-financial/pinch-ui-components";
import {getOperationResult} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {getFixedT} from "util/languageUtil";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {trackEvent} from "util/eventUtil";
import {MortgageTypePage as MortgageTypePageUI} from "@pinch-financial/pinch-ui-components";
import {getNavigationError} from "store/selectors/navigationController";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {next} from "./MortgageTypePage.action";

const fixedT = getFixedT("mortgageTypePage");

interface Props {
  onPrevPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const MortgageTypePage = ({containerRef, onPrevPage = () => {}}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locked = useSelector(getIsLocked);
  const {email} = useSelector(getContactInfo);
  const storedLoanType = useSelector(getLoanTypeConsolidated);
  const [loanType, setLoanType] = useState(storedLoanType);
  const [fieldError, setFieldError] = useState<string>();
  const navigationError = useSelector(getNavigationError);

  const {isPending} = useSelector(getOperationResult)(OperationType.submitLoanType);

  function onBack() {
    trackEvent(TrackingEventType.mortgageSelectionClickBack);
    onPrevPage();
  }

  function onSetLoanType(loanType: LoanType, trackingEventType: TrackingEventType) {
    if (locked) {
      setFieldError(fixedT("messages.appLocked"));
    } else {
      trackEvent(trackingEventType);
      setLoanType(loanType);
      dispatch(next({navigate, data: loanType}));
    }
  }

  useEffect(() => {
    setFieldError(navigationError ?? "");
  }, [navigationError]);

  return (
    <MortgageTypePageUI
      containerRef={containerRef}
      header={fixedT("header")}
      description={fixedT("description")}
      tipHeader={fixedT("tipHeader")}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody", {email: email})}} />}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      backButtonText={i18next.t("backButton")}
      storedLoanType={loanType}
      pageError={fieldError}
      disabled={locked || isPending}
      onPrevPage={onBack}
      onClickNewPurchase={() => {
        onSetLoanType(LoanType.NEW_PURCHASE, TrackingEventType.mortgageSelectionClickNewPurchase);
      }}
      newPurchaseTitle={fixedT("newMortgage")}
      newPurchaseSubtitle={fixedT("newMortgageSubtitle")}
      onClickPreapproval={() => {
        onSetLoanType(LoanType.PREAPPROVAL, TrackingEventType.mortgageSelectionClickPreApproval);
      }}
      preapprovalTitle={fixedT("preapproval")}
      preapprovalSubtitle={fixedT("preapprovalSubtitle")}
      onClickRenewal={() => {
        onSetLoanType(LoanType.RENEWAL, TrackingEventType.mortgageSelectionClickRenewal);
      }}
      renewalTitle={fixedT("renewal")}
      renewalSubtitle={fixedT("renewalSubtitle")}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default MortgageTypePage;
