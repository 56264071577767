import {optional} from "./basic";

export const enum OperationType {
  fetchTheme = "fetchTheme",
  fetchWhitelabel = "fetchWhitelabel",
  fetchMortgageProviders = "fetchMortgageProviders",
  fetchWhitelabelConfigurations = "fetchWhitelabelConfigurations",
  fetchProviderConfigurations = "fetchProviderConfigurations",
  fetchAuthorizedUser = "fetchAuthorizedUser",
  fetchUser = "fetchUser",
  fetchPrivacy = "fetchPrivacy",
  fetchTermsOfService = "fetchTermsOfService",
  fetchQualificationStatus = "fetchQualificationStatus",
  fetchApplicantStatus = "fetchApplicantStatus",
  fetchLenders = "fetchLenders",
  verifyRecoveryToken = "verifyRecoveryToken",
  signIn = "signIn",
  signOut = "signOut",
  registerPrimaryUser = "registerPrimaryUser",
  registerSecondaryUser = "registerSecondaryUser",
  setPassword = "setPassword",
  changeLanguage = "changeLanguage",
  fetchBasicInfoByUserId = "fetchBasicInfoByUserId",
  fetchFinancialInstitutionIdAndName = "fetchFinancialInstitutionIdAndName",
  acceptPrivacy = "acceptPrivacy",
  acceptTermsAndConditions = "acceptTermsAndConditions",
  acceptTermsOfService = "acceptTermsOfServices",
  submitPersonalProfile = "submitPersonalProfile",
  submitEmploymentInfo = "submitEmploymentInfo",
  submitAddressInfo = "submitAddressInfo",
  submitNewBank = "submitNewBank",
  submitLegalNamesSin = "submitLegalNamesSin",

  // documents
  submitT4 = "submitT4",
  submitNoa = "submitNoa",
  submitPaystub = "submitPaystub",
  submitBankStatement = "submitBankStatement",
  submitMls = "submitMls",
  submitClosingDocument = "submitClosingDocument",

  submitLoanType = "submitLoanType",
  submitMortgageInfo = "submitMortgageInfo",
  submitPropertyInformation = "submitPropertyInformation", // new-purchase/renew/switch
  submitUnresolvedAVMProperty = "submitUnresolvedAVMProperty", // new-purchase/renew
  setLender = "setLender",
  submitSelfReportedIncomeAssets = "submitSelfReportedIncomeAssets", // direct lender op
  submitSelfReportedLiabilities = "submitSelfReportedLiabilities", // direct lender op
  submitSelfReportedCreditScore = "submitSelfReportedCreditScore", // direct lender
  submitPurchaseTimeframeOperation = "submitPurchaseTimeframeOperation",

  // primary invites a secondary applicant, by email
  inviteSecondaryApplicant = "inviteSecondaryApplicant",

  pushNavbarUrlHistory = "pushNavbarUrlHistory",
  pushNavbarStepCompletion = "pushNavbarStepCompletion",

  // Fetch app pages
  fetchAppPages = "fetchAppPages",

  fetchAppAssociatedFinancialInstitution = "fetchAppAssociatedFinancialInstitution",
  fetchAppAssociatedFinancialInstitutionByAppOrAuthId = "fetchAppAssociatedFinancialInstitutionByAppOrAuthId",
}

export const enum OperationStatus {
  pending = "pending",
  success = "success",
  failure = "failure",
}

export interface ErrorResponse {
  message?: string;
  service?: string;
  errors?: [];
}

export interface WritableError {
  message: string | ErrorResponse;
  status: number;
  statusText: string;
  type: ResponseType;
  url: string;
}

export type OperationPendingPayload = OperationType;

export type OperationSuccessPayload = OperationType;

export type OperationFailurePayload = [type: OperationType, error: Partial<WritableError>];

export type OperationPayload =
  | OperationSuccessPayload
  | OperationPendingPayload
  | OperationFailurePayload;

export type OperationStateValue =
  | OperationStatus.pending
  | OperationStatus.success
  | Partial<WritableError>
  | undefined;

export interface OperationState {
  [key: OperationType | string]: OperationStateValue;
}

export interface OperationResultConsolidated {
  isInitial: boolean; // The given Operation is not triggered. So neither pending nor done
  isPending: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  isDone: boolean; // success or failure
  failure?: string | any; // Original BE error response object. Need a consistent data structure, so FE can display proper UI message, not whatever BE sends.
  status?: number; // http status code. Only failed operation has status stored in state
}

export type TypedFailure = {type: OperationType; failure: optional<WritableError>};
