import {createRoot} from "react-dom/client";
import "./tracer";
import "./mixpanel";
import Root from "./pages/Root/Root";
import {Provider} from "react-redux";
import store, {persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet";

declare var VERSION: string;
(window as any).VERSION = VERSION;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Helmet>
          <style>{"body {background-color: #f2f2f2 !important;}"}</style>
        </Helmet>
        <Root />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
