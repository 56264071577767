import {createReducer} from "@reduxjs/toolkit";
import {signOutOperation} from "store/operation/operations";
import {fetchLendersOperation} from "store/operation/qualification";
import {QualificationState} from "types/qualification";

export const initialState: QualificationState = {
  lenders: null,
};

const qualificationReducer = createReducer<QualificationState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.pending, () => initialState)
    .addCase(fetchLendersOperation.success, (state, {payload: lenders}) => {
      return {...state, lenders};
    })
);

export default qualificationReducer;
