import {logEmpty} from "components/utils/log";
import {equalIgnoreCase} from "components/utils/stringUtil";
import {call, select, takeEvery} from "redux-saga/effects";
import {submitUnresolvedAVMPropertyOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getLoanTypeConsolidated, getShouldRetryAvm} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType, WithNavigatePayload} from "@pinch-financial/pinch-ui-components";
import {Step} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {WithNavigate} from "types/ui";
import {
  initialize,
  submit,
  SubmitUnresolvedAVMPagePayload,
} from "./UnresolvedAVMPage.action";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";

function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const loanType: LoanType = yield select(getLoanTypeConsolidated);
  const shouldRetry: boolean = yield select(getShouldRetryAvm);

  if (!equalIgnoreCase(LoanType.NEW_PURCHASE, loanType)) {
    console.error("Wrong path here - not supported for loanType=" + loanType);
    navigate && navigate(AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO);
  }
  if (!shouldRetry) {
    navigate && navigate(AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO);
  }
}

function* onSubmit({
  payload: {navigate, data, onNextPage},
}: WithPayload<WithNavigatePayload<SubmitUnresolvedAVMPagePayload>>) {
  logEmpty(navigate, "navigate");

  const userId: string = yield select(getUserId);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails: data,
  };

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitUnresolvedAVMPropertyOperation.saga,
    userId,
    basicInfoRequest,
    Step.UNRESOLVED_AVM_PROPERTY
  );

  if (Operation.isSuccess(result)) {
    onNextPage && onNextPage();
  }
}

export default function* unresolvedAVMPageSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submit, onSubmit);
}
