import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {getFixedT} from "util/languageUtil";
import {DisclosurePage as DisclosurePageUI} from "@pinch-financial/pinch-ui-components";
import {acceptPrivacy} from "./DisclosurePage.action";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";

const fixedT = getFixedT("disclosurePage");

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const DisclosurePage = ({containerRef}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <DisclosurePageUI
      containerRef={containerRef}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      header={fixedT("header")}
      handleAgree={() => {
        trackEvent(TrackingEventType.personalProfileConsentAgree);
        dispatch(acceptPrivacy({navigate}));
      }}
      handleDisagree={() => {
        trackEvent(TrackingEventType.personalProfileConsentDisagree);
        navigate(AppRouteUrl.DECLINED_TERMS_URL);
      }}
      backButtonText={fixedT("backButton")}
      nextButtonText={fixedT("forwardButton")}
      items={[
        fixedT("consentItems.0"),
        fixedT("consentItems.1"),
        fixedT("consentItems.2"),
        fixedT("consentItems.3"),
        fixedT("consentItems.4"),
      ]}
    />
  );
};

export default DisclosurePage;
