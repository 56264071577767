import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitAddressInfoOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {submitAddressInfo} from "./AddressInfoPage.action";
import {AddressInfoPageFormData, WithNavigatePayload} from "@pinch-financial/pinch-ui-components";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
} from "store/selectors/navigationController";
import {getNextPageUrl} from "util/routeUtil";
import {getConsolidatedBasicInfo} from "store/selectors/basicInfo";
import {saveBasicInfo} from "store/actions/applicantMeta.action";

export function* onSubmit({payload}: WithPayload<WithNavigatePayload<AddressInfoPageFormData>>) {
  const userId: string = yield select(getUserId);
  const {navigate, data: requestPayload, shouldStay} = payload;

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoResponse> = {
    residentialAddress: {
      unitNo: requestPayload?.residentialAddress?.unitNo,
      streetNo: requestPayload?.residentialAddress?.streetNo
        ? Number(requestPayload.residentialAddress.streetNo)
        : undefined,
      streetName: requestPayload?.residentialAddress?.streetName,
      streetType: requestPayload?.residentialAddress?.streetType,
      streetDirection: requestPayload?.residentialAddress?.streetDirection,
      city: requestPayload?.residentialAddress?.city,
      province: requestPayload?.residentialAddress?.province,
      postCode: requestPayload?.residentialAddress?.postCode,
    },
    previousResidentialAddress: {
      unitNo: requestPayload?.previousResidentialAddress?.unitNo,
      streetNo: requestPayload?.previousResidentialAddress?.streetNo
        ? Number(requestPayload.previousResidentialAddress.streetNo)
        : undefined,
      streetName: requestPayload?.previousResidentialAddress?.streetName,
      streetType: requestPayload?.previousResidentialAddress?.streetType,
      streetDirection: requestPayload?.previousResidentialAddress?.streetDirection,
      city: requestPayload?.previousResidentialAddress?.city,
      province: requestPayload?.previousResidentialAddress?.province,
      postCode: requestPayload?.previousResidentialAddress?.postCode,
    },
    timeAtAddress: requestPayload?.timeAtAddress,
    ownershipStatus: requestPayload?.ownershipStatus,
  };

  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    yield call(
      navigate,
      getNextPageUrl(
        AppRouteUrl.BASIC_INFO_URL_ADDRESS,
        activeRouteSequence,
        basicInfo,
        hasNavbarAssetLiabilitiesStepClicked
      )
    );
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitAddressInfoOperation.saga,
    userId,
    requestPayload,
    Step.ADDRESS_INFO
  );

  if (!shouldStay && Operation.isSuccess(result)) {
    yield call(
      navigate,
      getNextPageUrl(
        AppRouteUrl.BASIC_INFO_URL_ADDRESS,
        activeRouteSequence,
        basicInfo,
        hasNavbarAssetLiabilitiesStepClicked
      )
    );
  }
}

export default function* addressInfoSaga() {
  yield takeEvery(submitAddressInfo, onSubmit);
}
