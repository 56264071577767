import {useState} from "react";
import i18next from "i18next";
import {useSelector} from "react-redux";
import {resetPasswordApi} from "api/auth";
import {getFixedT} from "util/languageUtil";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {getSchema} from "./ResendEmailPage.schema";
import {
  DoubleColumnPageTemplate,
  Form,
  Message,
  paperAirplane,
  SubmitButton,
  TextFieldControlled,
} from "@pinch-financial/pinch-ui-components";
import RegistrationComplete from "components/RegistrationComplete/RegistrationComplete";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NavigationBar from "components/NavigationBar/NavigationBar";
import {getIsEmailVerified} from "store/selectors/auth";
import {getHasUserAccount} from "store/selectors/user";

const fixedT = getFixedT("resendEmailPage");
interface ResendEmailPagePayload {
  email: string;
  confirmEmail: string;
}

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const ResendEmailPage = ({containerRef}: Props) => {
  const [sentEmail, setSentEmail] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const whiteLabelId = useSelector(getWhitelabelId);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const hasUser = useSelector(getHasUserAccount);
  const isEmailVerified = useSelector(getIsEmailVerified);

  const formHook = useForm<ResendEmailPagePayload>({
    defaultValues: {
      email: "",
      confirmEmail: "",
    },
    resolver: yupResolver(getSchema()),
  });

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  async function submitEmail(values: ResendEmailPagePayload) {
    setError("");
    setSubmitting(true);

    const {error} = await resetPasswordApi(
      whiteLabelId!,
      values.email,
      location.host,
      i18next.language
    );

    setSubmitting(false);
    if (error) {
      console.error("resetPasswordApi failed", error);
      setError(fixedT("error")!);
      return;
    }
    setSentEmail(true);
  }

  return (
    <DoubleColumnPageTemplate
      isMobileKeyboardOpen={isKeyboardOpen}
      header={fixedT("header", {context: hasUser && !isEmailVerified ? "verify" : "forgot"})}
      description={fixedT("description", {
        context: hasUser && !isEmailVerified ? "verify" : "forgot",
      })}
      navigationBar={<NavigationBar />}
      iconSrc={paperAirplane}
      containerRef={containerRef}
      nextButton={
        !sentEmail ? (
          <SubmitButton
            isEnabled={
              !submitting &&
              !!formHook.getValues("email") &&
              formHook.getValues("email") === formHook.getValues("confirmEmail")
            }
            onClick={formHook.handleSubmit(submitEmail)}
          >
            {fixedT("forwardButton")}
          </SubmitButton>
        ) : undefined
      }
    >
      {sentEmail ? (
        <RegistrationComplete />
      ) : (
        <Form onEnter={formHook.handleSubmit(submitEmail)}>
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="email"
            type="email"
            label={fixedT("fields.email.label")}
            placeholder={fixedT("fields.email.placeholder")!}
            disabled={submitting}
            autoComplete={"email"}
            required
          />
          <TextFieldControlled
            fullWidth
            formHook={formHook}
            name="confirmEmail"
            type="email"
            label={fixedT("fields.confirmEmail.label")}
            placeholder={fixedT("fields.confirmEmail.placeholder")!}
            disabled={submitting}
            autoComplete={"email"}
            required
            triggerOnChange
          />
          <Message message={error} />
        </Form>
      )}
    </DoubleColumnPageTemplate>
  );
};

export default ResendEmailPage;
