import {WithNavigate} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {Province} from "types/enums/province";

const namespace = "ProvinceSelectPage";

export interface ProvinceSelectPagePayload extends WithNavigate {
  selectedProvince: Province;
  onNextPage?: () => any;
}

export const submit = createAction<ProvinceSelectPagePayload>(actionTypeOf(namespace, "submit"));
