import {createSelector} from "@reduxjs/toolkit";
import {AVM_MAX_RETRIES} from "appConstants";
import {BasicInfoState} from "types/basicInfo";
import {Bank} from "types/dto/bank";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {ResidentialApplicationType} from "types/enums/residentialApplicationType";
import {State} from "types/store";
import {getUtm} from "util/localStorageUtil";
import {getHasFeatureSin} from "./theme";
import {getUser} from "./user";
import {getDefaultProviderId, getProducts, getSelectedProviderId} from "./whitelabel";
import {AppRouteUrl, LoanType, QueryParamKeys} from "@pinch-financial/pinch-ui-components";
import {ResidentialMortgageDetailsResponse} from "@pinch-financial/pinch-ui-components/dist/types/dto/residentialMortgageDetailsResponse";
import {getApplicantMetaBasicInfoOrEmpty, getLoanType} from "./applicantMeta";

export const getBasicInfo = (rootState: State): BasicInfoState => rootState.basicInfo;

export const getBasicInfoOrEmpty = (rootState: State): ResidentialApplicationBasicInfoResponse =>
  rootState.basicInfo || ({} as ResidentialApplicationBasicInfoResponse);

export const getConsolidatedBasicInfo = createSelector(
  getBasicInfoOrEmpty,
  getApplicantMetaBasicInfoOrEmpty,
  (basicInfo, applicantMetaBasicInfo) => {
    return {
      ...applicantMetaBasicInfo,
      ...basicInfo,
    };
  }
);

export const getAppId = createSelector(
  getConsolidatedBasicInfo,
  (basicInfo) => basicInfo?.applicationPublicId
);

export const getApplicantId = createSelector(
  getConsolidatedBasicInfo,
  (basicInfo) => basicInfo?.applicantDetailsPublicId
);

export const getIsPrimary = createSelector(
  getBasicInfo,
  (basicInfo) => !basicInfo || Boolean(basicInfo?.isPrimaryApplicant)
);

export const getIsSecondary = createSelector(
  getBasicInfo,
  (basicInfo) => Boolean(basicInfo) && Boolean(!basicInfo?.isPrimaryApplicant)
);

export const getHasSecondary = createSelector(
  getBasicInfo,
  (basicInfo) => Boolean(basicInfo) && Boolean(basicInfo?.secondaryUserEmail)
);

export const getAcceptedPrivacyPolicy = createSelector(
  getConsolidatedBasicInfo,
  (info) => !!info?.acceptedPrivacyPolicyAt
);

export const getAcceptedTermsOfService = createSelector(
  getConsolidatedBasicInfo,
  (info) => !!info?.acceptedTermsOfServiceAt
);

export const getNumOfBanks = createSelector(getConsolidatedBasicInfo, (info) => {
  return (
    Number(Boolean(info?.firstBankLoginId)) +
    Number(Boolean(info?.secondBankLoginId)) +
    Number(Boolean(info?.thirdBankLoginId))
  );
});

export const getIsFirstBankRequestDone = createSelector(getConsolidatedBasicInfo, (info) => {
  return info?.isFirstBankRequestDone;
});

export const getFirstBankFinancialDataId = createSelector(getConsolidatedBasicInfo, (info) => {
  return info?.firstBankFinancialDataPublicId;
});

export const getIsSecondBankRequestDone = createSelector(getConsolidatedBasicInfo, (info) => {
  return info?.isSecondBankRequestDone;
});

export const getSecondBankFinancialDataId = createSelector(getConsolidatedBasicInfo, (info) => {
  return info?.secondBankFinancialDataPublicId;
});

export const getIsThirdBankRequestDone = createSelector(getConsolidatedBasicInfo, (info) => {
  return info?.isThirdBankRequestDone;
});

export const getThirdBankFinancialDataId = createSelector(getConsolidatedBasicInfo, (info) => {
  return info?.thirdBankFinancialDataPublicId;
});

export const getIsBankRequestLoading = createSelector(
  getIsFirstBankRequestDone,
  getFirstBankFinancialDataId,
  getIsSecondBankRequestDone,
  getSecondBankFinancialDataId,
  getIsThirdBankRequestDone,
  getThirdBankFinancialDataId,
  (
    isFirstBankRequestDone,
    firstBankFinancialDataId,
    isSecondBankRequestDone,
    secondBankFinancialDataId,
    isThirdBankRequestDone,
    thirdBankFinancialDataId
  ) =>
    (!isFirstBankRequestDone && Boolean(firstBankFinancialDataId)) ||
    (!isSecondBankRequestDone && Boolean(secondBankFinancialDataId)) ||
    (!isThirdBankRequestDone && Boolean(thirdBankFinancialDataId))
);

export const getHasBank = createSelector(getNumOfBanks, (numOfBanks) => numOfBanks > 0);

export const getCreditReportError = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.creditReportError
);

export const getCreditReportFailedAttempts = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.creditReportFailedAttempts
);

export const getIsLocked = createSelector(getConsolidatedBasicInfo, (info) =>
  Boolean(info?.recordIsLocked)
);

export const getCanAddBank = createSelector(
  getConsolidatedBasicInfo,
  getIsLocked,
  getNumOfBanks,
  (info, isLocked, numOfBanks) => {
    return Boolean(info) && !isLocked && numOfBanks < 3;
  }
);

export const getBanks = createSelector(getConsolidatedBasicInfo, (info) => {
  return [
    {
      loginId: info?.firstBankLoginId,
      financialDataId: info?.firstBankFinancialDataPublicId,
      error: info?.firstBankRequestError,
      isDone: info?.isFirstBankRequestDone,
    },
    {
      loginId: info?.secondBankLoginId,
      financialDataId: info?.secondBankFinancialDataPublicId,
      error: info?.secondBankRequestError,
      isDone: info?.isSecondBankRequestDone,
    },
    {
      loginId: info?.thirdBankLoginId,
      financialDataId: info?.thirdBankFinancialDataPublicId,
      error: info?.thirdBankRequestError,
      isDone: info?.isThirdBankRequestDone,
    },
  ].filter((bank) => Boolean(bank.financialDataId)) as Bank[];
});

export const getIsAnyBankPending = createSelector(getBanks, (banks) => {
  return banks.some((bank) => !bank.isDone);
});

export const getIsSinRequired = createSelector(
  getHasFeatureSin,
  getConsolidatedBasicInfo,
  (hasFeatureSinInTheme, basicInfo) => hasFeatureSinInTheme && Boolean(basicInfo?.sinRequired)
);

export const getNamesConsolidated = createSelector(
  getUser,
  getConsolidatedBasicInfo,
  (user, basicInfo) => {
    return {
      firstName: basicInfo?.legalFirstName || user?.firstName,
      lastName: basicInfo?.legalLastName || user?.lastName,
    };
  }
);

export const getMortgageDetails = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.mortgageDetails
);

export const getLoanTypeFromApp = createSelector(
  getMortgageDetails,
  (mortgageDetails): LoanType | undefined => mortgageDetails?.loanType
);

export const getLoanTypeConsolidated = createSelector(
  getMortgageDetails,
  getLoanType,
  (mortgageDetails, loanType): LoanType | undefined => mortgageDetails?.loanType ?? loanType
);

export const getMortgageProviderIdFromApp = createSelector(
  getMortgageDetails,
  (mortgageDetails) => mortgageDetails?.mortgageProvider
);

export const getMortgageProviderIdFromAppOrSelected = createSelector(
  getMortgageProviderIdFromApp,
  getSelectedProviderId,
  getDefaultProviderId,
  (providerInApp, selectedProvider, defaultProvider) =>
    providerInApp || selectedProvider || defaultProvider
);

export const getConsolidatedMortgageProvider = createSelector(
  getMortgageProviderIdFromAppOrSelected,
  getProducts,
  (providerId, allProviders) =>
    allProviders?.find((provider) => provider?.financialInstitutionPublicId === providerId)
);

export const getConsolidatedMortgageProviderName = createSelector(
  getConsolidatedMortgageProvider,
  (provider) => provider?.organizationName
);

export const getConsolidatedMortgageProviderEmail = createSelector(
  getConsolidatedMortgageProvider,
  (provider) => provider?.financialInstitutionPrimaryEmail
);

export const getConsolidatedMortgageProviderPhoneNumber = createSelector(
  getConsolidatedMortgageProvider,
  (provider) => provider?.financialInstitutionPhoneNumber
);

export const getMortgageDetailsOrEmpty = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.mortgageDetails || ({} as ResidentialMortgageDetailsResponse)
);

export const getPurchaseOrRenewalPropertyAddress = createSelector(
  getMortgageDetails,
  (mortgageDetails) => {
    if (!mortgageDetails) {
      return undefined;
    }
    return {
      unitNo: mortgageDetails.unitNo,
      streetNo: mortgageDetails.streetNo,
      streetName: mortgageDetails.streetName,
      city: mortgageDetails.city,
      province: mortgageDetails.province,
      postCode: mortgageDetails.postCode,
    };
  }
);

export const getPotentialAddressMatches = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.potentialAddressMatches
);

export const getPotentialAddressMatchesOrEmpty = createSelector(
  getPotentialAddressMatches,
  (matches) => matches || []
);

export const getHasAvm = createSelector(getConsolidatedBasicInfo, (info) =>
  Boolean(info?.avmResultPublicId)
);

export const getAVMFailed = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.avmFailed || false
);

export const getShouldRetryAvm = createSelector(
  getConsolidatedBasicInfo,
  (info) =>
    info?.avmFailed &&
    (info?.avmFailedAttempts || 0) < AVM_MAX_RETRIES &&
    info.potentialAddressMatches
);

export const getApplicationType = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.applicationType
);

export const getIsJointApp = createSelector(
  getApplicationType,
  (appType) => ResidentialApplicationType.JOINT_APPLICANT === appType
);

export const getIsPrimaryAndHasSecondary = createSelector(
  getConsolidatedBasicInfo,
  (info) => Boolean(info?.isPrimaryApplicant) && Boolean(info?.secondaryUserEmail)
);

export const getBrokerCode = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.brokerCode || getUtm()[QueryParamKeys.BROKER_CODE]
);

export const getContactInfo = createSelector(
  getConsolidatedMortgageProviderEmail,
  getConsolidatedMortgageProviderPhoneNumber,
  (email, phoneNumber) => {
    return {
      email: email,
      phoneNumber: phoneNumber,
    };
  }
);

export const getHasCreditSuccess = createSelector(getConsolidatedBasicInfo, (info) => {
  return !!info?.creditReportPublicId && !info.creditReportError && !info.creditReportNoHit;
});

export const getSelfReportedAssetsInApp = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.selfReportedAssets
);

export const getSelfReportedLiabilitiesInApp = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.selfReportedLiabilities
);

export const getHasSelfReportedAssetsInApp = createSelector(
  getSelfReportedAssetsInApp,
  (assets) => !!assets && assets.length > 0
);

export const getHasSelfReportedLiabilitiesInApp = createSelector(
  getSelfReportedLiabilitiesInApp,
  (liabilities) => !!liabilities && liabilities.length > 0
);

export const getSelfReportedCreditScore = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.creditScore
);

export const getHasSelfReportedCreditScore = createSelector(
  getSelfReportedCreditScore,
  (score) => score != null
);

export const getHasPulledEquifax = createSelector(getConsolidatedBasicInfo, (info) =>
  Boolean(info?.creditReportPublicId)
);

export const getHasEquifaxCreditOrSelfReported = createSelector(
  getHasPulledEquifax,
  getHasSelfReportedCreditScore,
  (pulledEquifax, hasSelfReported) => pulledEquifax || hasSelfReported
);

export const getCurrentRouteUrlKey = createSelector(
  getConsolidatedBasicInfo,
  (info) => info?.currentApplicationRoute
);

// actual route url used for navigation
export const getCurrentRouteUrlValue = createSelector(getCurrentRouteUrlKey, (key) =>
  key ? AppRouteUrl[key as keyof typeof AppRouteUrl] : undefined
);
