import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitPurchaseTimeframeOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {calculateStepStatusChange} from "components/NavigationBar/NavigationBar.action";
import {NavStep} from "@pinch-financial/pinch-ui-components";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {PurchaseTimeframePagePayload, submit} from "./PurchaseTimeframePage.action";

export function* onSubmit({
  payload: {purchaseTimeframe, onNextPage},
}: WithPayload<PurchaseTimeframePagePayload>) {
  const userId: string = yield select(getUserId);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    purchaseTimeframe,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage?.();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitPurchaseTimeframeOperation.saga,
    userId,
    basicInfoRequest,
    Step.PURCHASE_TIMEFRAME
  );
  yield put(calculateStepStatusChange(NavStep.MORTGAGE_TYPE));
  if (Operation.isSuccess(result)) {
    onNextPage && onNextPage();
  }
}

export default function* purchaseTimeframePageSaga() {
  yield takeEvery(submit, onSubmit);
}
