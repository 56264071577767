import {equalIgnoreCase} from "components/utils/stringUtil";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitPropertyInformationOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {
  getConsolidatedBasicInfo,
  getLoanTypeConsolidated,
  getPotentialAddressMatchesOrEmpty,
  getShouldRetryAvm,
} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {LoanType, WithNavigatePayload} from "@pinch-financial/pinch-ui-components";
import {Step} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {WithNavigate} from "types/ui";
import {
  initialize,
  navigatePreviousStep,
  submitPropertyInformation,
} from "./PropertyAddressInfoPage.action";
import {
  PropertyAddressDto,
  PropertyAddressInfoPageFormData,
  ResidentialApplicationBasicInfoRequest,
} from "@pinch-financial/pinch-ui-components";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {getNextPageUrl, getPreviousPageUrl} from "util/routeUtil";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
  getPreviousUrlFromHistory,
} from "store/selectors/navigationController";

export function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const loanType: LoanType = yield select(getLoanTypeConsolidated);
  if (!loanType) {
    console.info("Redirect to select loan type due to loan type missing");
    navigate(AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE);
  }
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);

  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );

  const previousPage: AppRouteUrl = yield select(getPreviousUrlFromHistory);
  const nextPageUrl = getNextPageUrl(
    AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );
  const previousPageUrl = getPreviousPageUrl(
    AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );

  if (equalIgnoreCase(LoanType.PREAPPROVAL, loanType)) {
    console.warn("Loan type not valid for this page. Redirecting...");
    // if the next page in the history is the previous page, we used the back button
    if (previousPage === nextPageUrl) {
      yield call(navigate, previousPageUrl);
    } else {
      yield call(navigate, nextPageUrl);
    }
  }
}

export function* onSubmit({
  payload: {shouldStay, navigate, data: mortgageDetails, onNextPage},
}: WithPayload<WithNavigatePayload<PropertyAddressInfoPageFormData>>) {
  const userId: string = yield select(getUserId);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    mortgageDetails,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage?.();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitPropertyInformationOperation.saga,
    userId,
    basicInfoRequest,
    Step.PROPERTY_INFO
  );
  const potentialAddressMatches: PropertyAddressDto[] = yield select(
    getPotentialAddressMatchesOrEmpty
  );
  const retry: boolean = yield select(getShouldRetryAvm);

  if (retry) {
    if (potentialAddressMatches.length > 0 && navigate) {
      yield call(navigate, AppRouteUrl.UNRESOLVED_AVM_URL);
    }
    return;
  }

  if (Operation.isSuccess(result) && !shouldStay) {
    onNextPage?.();
  }
}

export function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  navigate(AppRouteUrl.INVITE_SECONDARY_APPLICANT);
}

export default function* propertyAddressInfoPageSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(submitPropertyInformation, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
