import i18next from "i18next";
import {LanguageType} from "types/enums/languageType";

const getIntlLocal = (): "en-CA" | "fr-CA" => {
  return i18next.language === LanguageType.FR_CA ? "fr-CA" : "en-CA";
};

export function formatAsPercentage(number: number): string {
  return new Intl.NumberFormat(getIntlLocal(), {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  }).format(number / 100);
}

export function formatNumberAsPercentage(number: number): number {
  return parseFloat((number * 100).toFixed(2));
}
