const MONTHS_IN_YEAR = 12;

export const monthToYear = (timeInMonths: number): number => {
  return Math.floor(timeInMonths / MONTHS_IN_YEAR);
};

export const wait = async (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const waitSeconds = async (seconds: number): Promise<void> => {
  return wait(seconds * 1000);
};
