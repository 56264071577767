import {ApplicationStatusResponse} from "types/dto/applicationStatusResponse";
import {ApplicationStep} from "types/enums/applicationStep";
import {StepStatus} from "types/enums/stepStatus";
import {StepLink} from "types/StepLink";

export const toRequiredStepLinks = (
  appStatus: ApplicationStatusResponse,
  navLinks: Record<string, StepLink>
): {
  requiredStepsPrimary: StepLink[];
  requiredStepsSecondary: StepLink[];
} => {
  // Currently only primary user sees the app-status, and will call this method
  const requiredStepsPrimary: StepLink[] = [];
  const requiredStepsSecondary: StepLink[] = [];
  for (let step in appStatus) {
    let status: StepStatus = appStatus[step as ApplicationStep];
    if (status === StepStatus.INCOMPLETE || status === StepStatus.MISSING_PRIMARY) {
      requiredStepsPrimary.push(navLinks[step]);
    } else if (status === StepStatus.MISSING_SECONDARY) {
      requiredStepsSecondary.push(navLinks[step]);
    }
  }
  return {
    requiredStepsPrimary: requiredStepsPrimary.filter(Boolean),
    requiredStepsSecondary: requiredStepsSecondary.filter(Boolean),
  };
};
