import {NavStep} from "@pinch-financial/pinch-ui-components";

export const NavStepCaption = (fixedT: (arg0: NavStep) => string): Record<NavStep, string> => {
  return {
    [NavStep.WELCOME]: fixedT(NavStep.WELCOME),
    [NavStep.LENDERS]: fixedT(NavStep.LENDERS),
    [NavStep.SIGN_UP]: fixedT(NavStep.SIGN_UP),
    [NavStep.PERSONAL_PROFILE]: fixedT(NavStep.PERSONAL_PROFILE),
    [NavStep.MORTGAGE_TYPE]: fixedT(NavStep.MORTGAGE_TYPE),
    [NavStep.BANKING]: fixedT(NavStep.BANKING),
    [NavStep.CREDIT]: fixedT(NavStep.CREDIT),
    [NavStep.SELECT_PRODUCT]: fixedT(NavStep.SELECT_PRODUCT),
  };
};
