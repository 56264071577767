import {createReducer} from "@reduxjs/toolkit";
import {unsetFinancialInstitution} from "store/actions/financialInstitution";
import {
  fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation,
  fetchAppAssociatedFinancialInstitutionOperation,
} from "store/operation/financialInstitution";

import {signOutOperation} from "store/operation/operations";
import {FinancialInstitutionState} from "types/financialInstitutionState";

const initialState: FinancialInstitutionState = {
  appAssociated: undefined,
};

const financialInstitutionReducer = createReducer<FinancialInstitutionState>(
  initialState,
  (builder) =>
    builder
      .addCase(signOutOperation.pending, () => initialState)
      .addCase(fetchAppAssociatedFinancialInstitutionOperation.success, (state, {payload}) => {
        return {
          ...state,
          appAssociated: payload,
        };
      })
      .addCase(
        fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation.success,
        (state, {payload}) => {
          return {
            ...state,
            appAssociated: payload,
          };
        }
      )
      .addCase(unsetFinancialInstitution, (state) => {
        return {
          ...state,
          appAssociated: undefined,
        };
      })
);

export default financialInstitutionReducer;
