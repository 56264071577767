import {logEmpty} from "components/utils/log";
import {call, select, takeEvery} from "redux-saga/effects";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {registerPrimaryUserOperation} from "store/operation/user";
import {
  getConsolidatedBasicInfo,
  getMortgageProviderIdFromApp,
  getMortgageProviderIdFromAppOrSelected,
} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {User} from "types/dto/user";
import {getNextPageUrl} from "util/routeUtil";
import {initialize, registerPrimaryUser, RegisterPrimaryUserPayload} from "./SignUpPage.action";
import {fetchInitRouteSequenceNavStatus} from "components/NavigationBar/NavigationBar.saga";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {UUID} from "@pinch-financial/pinch-ui-components";
import {WithNavigate} from "types/ui";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
} from "store/selectors/navigationController";
import {fetchAppAssociatedFinancialInstitutionOperation} from "store/operation/financialInstitution";

export function* onInitialize({payload: {navigate}}: WithPayload<WithNavigate>) {
  const financialInstitutionId: UUID = yield select(getMortgageProviderIdFromAppOrSelected);
  const userId: string = yield select(getUserId);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );
  if (!financialInstitutionId) {
    console.info("No financial institution id found. Redirecting to landing page.");
    navigate(AppRouteUrl.LANDING_PAGE_URL);
  }
  if (userId) {
    navigate(
      getNextPageUrl(
        AppRouteUrl.SIGN_UP,
        activeRouteSequence,
        basicInfo,
        hasNavbarAssetLiabilitiesStepClicked
      )
    );
  }
}

export function* onRegisteringPrimary({
  payload: {navigate, ...userRequest},
}: WithPayload<RegisterPrimaryUserPayload>) {
  const whiteLabelId: string = yield select(getWhitelabelId);
  const financialInstitutionId: UUID = yield select(getMortgageProviderIdFromAppOrSelected);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);

  logEmpty(financialInstitutionId, "financialInstitutionId");

  const signUpResult: SagaResult<User> = yield call(
    registerPrimaryUserOperation.saga,
    {
      ...userRequest,
      basicInfo,
    },
    whiteLabelId,
    location.host,
    financialInstitutionId
  );

  if (Operation.isSuccess(signUpResult)) {
    // Registering primary user would have created app, applicant(s).
    // Need to fetch the basicInfo which is unified view of app & applicant
    const userId: string = yield select(getUserId);

    const basicInfoResult: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
      fetchBasicInfoByUserIdOperation.saga,
      userId
    );

    if (Operation.isSuccess(basicInfoResult)) {
      const mortgageProvider: UUID = yield select(getMortgageProviderIdFromApp);
      yield call(fetchAppAssociatedFinancialInstitutionOperation.saga, mortgageProvider);
      yield call(fetchInitRouteSequenceNavStatus);
      const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
      const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(
        getConsolidatedBasicInfo
      );
      const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
        getHasNavbarAssetLiabilitiesStepClicked
      );

      yield call(
        navigate,
        getNextPageUrl(
          AppRouteUrl.SIGN_UP,
          activeRouteSequence,
          basicInfo,
          hasNavbarAssetLiabilitiesStepClicked
        )
      );
    }
  }
}

export default function* signUpPageSaga() {
  yield takeEvery(initialize, onInitialize);
  yield takeEvery(registerPrimaryUser, onRegisteringPrimary);
}
