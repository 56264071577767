import * as styles from "./GeneralPageTemplate.module.scss";
import {BaseProps} from "types/ui";
import {Paper} from "@mui/material";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";

interface Props extends BaseProps {
  icon?: string;
  loading?: boolean;
}
//TODO: replace it with lib's component
const GeneralPageTemplate = ({icon = undefined, loading = false, children, className}: Props) => {
  return (
    <Paper
      elevation={10}
      className={styles.content}
      sx={{
        transform: {
          laptop: `translateX(0)`,
        },
      }}
    >
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          {icon && <img className={styles.icon} src={icon} />}
          {children}
        </>
      )}
    </Paper>
  );
};

export default GeneralPageTemplate;
