import {WithDataError} from "types/api";
import {Configuration} from "types/configurations";
import {normalizedFetchApi} from "./util";
import {UUID} from "@pinch-financial/pinch-ui-components";

const baseUrl = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/configurations`;

export const fetchConfigurationsWhiteLabelApi = async (
  whiteLabelId: UUID
): Promise<WithDataError<Configuration[]>> =>
  normalizedFetchApi(() => fetch(`${baseUrl}/white-label/${whiteLabelId}`));

export const fetchConfigurationsFinancialInstitutionApi = async (
  financialInstitutionId: UUID
): Promise<WithDataError<Configuration[]>> =>
  normalizedFetchApi(() => fetch(`${baseUrl}/financial-institution/${financialInstitutionId}`));
