import {useSelector} from "react-redux";
import {getFixedT} from "util/languageUtil";
import {getBankProducts, getLenderProducts} from "store/selectors/whitelabel";
import {getIconDisplayMapper} from "store/selectors/configurations";
import {WelcomePage as WelcomePageUI} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";
import React from "react";
import {safeArray} from "components/utils/listUtil";

const fixedT = getFixedT("welcomePage");

interface Props {
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const WelcomePage = ({onNextPage, containerRef}: Props) => {
  const iconDisplayMapper = useSelector(getIconDisplayMapper);
  const bankProducts = useSelector(getBankProducts);
  const lenderProducts = useSelector(getLenderProducts);
  const products = [...(bankProducts ?? []), ...(lenderProducts ?? [])];

  return (
    <WelcomePageUI
      containerRef={containerRef}
      header={fixedT("header")}
      body1={fixedT("body1")}
      body2={fixedT("body2")}
      tipHeader={fixedT("tipHeader")}
      tipBody={fixedT("tipBody")}
      nextButtonText={fixedT("nextButton")}
      onNextPage={onNextPage}
      products={safeArray(products).map((product) => {
        const {icon, background} = iconDisplayMapper(product);
        return {
          id: product.financialInstitutionPublicId,
          iconSrc: icon!,
          backgroundColor: `rgb(${background})`,
          name: product.productName,
        };
      })}
      navigationBar={<NavigationBar />}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default WelcomePage;
