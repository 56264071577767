// 10 MB Client side file upload limit to proactively stop uploading any large files. Also done server side.
// The server has a limit of 10 MB, so this is slightly higher but not that much higher.
export const UPLOAD_FILE_SIZE_MAX_IN_MB = 10; // 10 MB
export const UPLOAD_FILE_SIZE_MAX_IN_BYTES = UPLOAD_FILE_SIZE_MAX_IN_MB * 1024 * 1024; // file.size

export const MOBILE_WIDTH = 1024;
export const DRAWER_WIDTH = 304;

export const BACKGROUND_COLOR_IMAGE_DEFAULT = "255, 255, 255";

export const AVM_MAX_RETRIES = 2;

export const TRACK_EVENT_DEBOUNCE_WAIT_MS = 250;
export const PINCH_MORTGAGE_PROVIDER = "mortgageProvider";
