import {useCallback, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Routes from "pages/_routes/Routes";
import {initialize} from "./Root.action";
import {getTheme} from "store/selectors/theme";
import {applyThemeToDocument} from "./themeUtil";
import {Theme} from "types/theme";
import "../../util/languageUtil";
import "components/Colors";
import "./Root.module.scss";
import i18next, {hasLoadedNamespace} from "i18next";
import {getIsAppInitialized} from "store/selectors/operation";
import {withTranslation} from "react-i18next";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import Page from "templates/Page/Page";
import {appTheme} from "@pinch-financial/pinch-ui-components";
import {PaletteColorOptions} from "@mui/material";
import {updateCurrentApplicationRoute} from "api/basicInfo";
import {getApplicantId} from "store/selectors/basicInfo";
import {debounce} from "lodash";
import {TRACK_EVENT_DEBOUNCE_WAIT_MS} from "appConstants";
import {getActiveRouteSequenceVisible} from "store/selectors/navigationController";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    laptop: true;
    desktop: true;
  }
}

declare module "@mui/material/styles" {
  interface CustomPalette {
    black: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    black: true;
  }
}

const Root = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useSelector(getTheme) as Theme;
  const isAppInitialized = useSelector(getIsAppInitialized);

  const applicantId = useSelector(getApplicantId);
  const location = useLocation();
  const visibleRoutes = useSelector(getActiveRouteSequenceVisible);
  // Return a memoized function, otherwise every re-render will create a debounced
  // function and the debounce will not work as expected.
  // See https://stackoverflow.com/a/61786423
  const debouncedUpdateCurrentApplicationRoute = useCallback(
    debounce(updateCurrentApplicationRoute, TRACK_EVENT_DEBOUNCE_WAIT_MS),
    [visibleRoutes]
  );

  useEffect(() => {
    const routeUrl = location.pathname;

    const ignoredRoutes = [
      "/",
      AppRouteUrl.ACTIVATE_ACCOUNT_URL,
      AppRouteUrl.ERROR_PAGE_URL,
      AppRouteUrl.SIGN_UP,
      AppRouteUrl.SIGN_IN,
      AppRouteUrl.LOGIN_CALLBACK,
      AppRouteUrl.RESEND_EMAIL_URL,
      AppRouteUrl.RESET_PASSWORD_URL,
      AppRouteUrl.ACTIVATE_ACCOUNT_URL,
      AppRouteUrl.SECONDARY_SIGNUP,
      AppRouteUrl.PRODUCT_FETCH_TIMEOUT_URL,
    ];

    if (applicantId && !ignoredRoutes.includes(routeUrl)) {
      const routeKeyValue = Object.entries(AppRouteUrl).find(([key, value]) => value === routeUrl);
      if (
        routeKeyValue &&
        routeKeyValue[0] &&
        visibleRoutes?.includes(routeKeyValue[1] as AppRouteUrl)
      ) {
        debouncedUpdateCurrentApplicationRoute(applicantId, routeKeyValue[0]);
      }
    }
  }, [applicantId, location, visibleRoutes]);

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  useEffect(() => {
    if (theme) {
      applyThemeToDocument(theme);
    }
  }, [theme]);

  if (
    !hasLoadedNamespace(i18next.language) ||
    (window.location.pathname !== AppRouteUrl.ERROR_PAGE_URL && !isAppInitialized) ||
    !theme
  ) {
    return (
      <ThemeProvider theme={appTheme}>
        <Page loading />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={appTheme}>
      <Routes></Routes>
    </ThemeProvider>
  );
};

export default withTranslation()(Root);
