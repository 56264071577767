import {logEmpty} from "components/utils/log";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitSelfReportedIncomeAssetsOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {submit, acceptMissingLiabilitiesDataWarning} from "./SelfReportedLiabilitiesPage.action";
import {SelfReportedLiabilityDto} from "types/dto/selfReportedLiabilityDto";
import {Currency} from "types/enums/currency";
import {SelfReportedLiabilityType} from "types/enums/selfReportedLiabilityType";
import {
  ResidentialApplicationBasicInfoRequest,
  SelfReportedLiabilitiesPageFormData,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {isBlank} from "components/utils/stringUtil";
import {saveBasicInfo} from "store/actions/applicantMeta.action";

export function* onSubmit({
  payload: {navigate, data, shouldStay, onNextPage},
}: WithPayload<WithNavigatePayload<SelfReportedLiabilitiesPageFormData>>) {
  logEmpty(navigate, "navigate");
  const userId: string = yield select(getUserId);

  let otherLiabilities =
    data.otherLiabilities?.map(
      (other) =>
        ({
          type: SelfReportedLiabilityType.OTHER,
          balance: {
            amount: other.amount,
            currency: Currency.CAD,
          },
          lender: other.description,
        } as SelfReportedLiabilityDto)
    ) || [];

  let liabilities: SelfReportedLiabilityDto[] = [
    {
      type: SelfReportedLiabilityType.CAR_PAYMENT,
      balance:
        !isBlank(data.carPayment) && !isNaN(data.carPayment!)
          ? {amount: data.carPayment!, currency: Currency.CAD}
          : undefined,
    },
    {
      type: SelfReportedLiabilityType.MORTGAGE,
      balance:
        !isBlank(data.mortgage) && !isNaN(data.mortgage!)
          ? {amount: data.mortgage!, currency: Currency.CAD}
          : undefined,
    },
    {
      type: SelfReportedLiabilityType.LOANS,
      balance:
        !isBlank(data.loans) && !isNaN(data.loans!)
          ? {amount: data.loans!, currency: Currency.CAD}
          : undefined,
    },
    ...otherLiabilities,
  ].filter((liability) => liability.balance?.amount !== undefined);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    selfReportedLiabilities: liabilities,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage && onNextPage();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitSelfReportedIncomeAssetsOperation.saga,
    userId,
    basicInfoRequest,
    Step.SELF_REPORTED_LIABILITIES
  );
  if (Operation.isSuccess(result)) {
    yield put(acceptMissingLiabilitiesDataWarning(true)); // User successfully submitted liabilities, setting the flag just so user is not get warned any more.
    !shouldStay && onNextPage && onNextPage();
  }
}

export function* onAcceptMissingLiabilitesDataWarning({payload}: WithPayload) {
  const {hasUserAcceptedLiablitiesDataWarning} = payload;
  if (hasUserAcceptedLiablitiesDataWarning) {
    yield put(acceptMissingLiabilitiesDataWarning(hasUserAcceptedLiablitiesDataWarning));
  }
}

export default function* selfReportedLiabilitiesSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(acceptMissingLiabilitiesDataWarning, onAcceptMissingLiabilitesDataWarning);
}
