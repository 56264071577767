import {createReducer, PayloadActionCreator} from "@reduxjs/toolkit";
import {
  acceptPrivacyOperation,
  fetchBasicInfoByUserIdOperation,
  submitLoanTypeOperation,
  submitAddressInfoOperation,
  submitEmploymentInfoOperation,
  submitLegalNamesSinOperation,
  submitNewBankOperation,
  submitNoaOperation,
  submitPersonalProfileOperation,
  submitT4Operation,
  submitMortgageInfoOperation,
  submitPropertyInformationOperation,
  submitUnresolvedAVMPropertyOperation,
  submitSelfReportedIncomeAssetsOperation,
  submitSelfReportedLiabilitiesOperation,
  submitPaystubOperation,
  submitBankStatementOperation,
  submitMlsOperation,
  submitClosingDocumentOperation,
  submitSelfReportedCreditScoreOperation,
  inviteSecondaryApplicantOperation,
} from "store/operation/basicInfo";
import {signOutOperation} from "store/operation/operations";
import {setLenderOperation} from "store/operation/basicInfo";
import {nullable} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {BasicInfoState} from "types/basicInfo";

const initialState: BasicInfoState = null;

const BASIC_INFO_UPDATE_ACTION_TYPES = [
  acceptPrivacyOperation,
  submitPersonalProfileOperation,
  submitEmploymentInfoOperation,
  submitAddressInfoOperation,
  submitNewBankOperation,
  submitLegalNamesSinOperation,
  submitT4Operation,
  submitNoaOperation,
  submitPaystubOperation,
  submitBankStatementOperation,
  submitMlsOperation,
  submitClosingDocumentOperation,
  submitLoanTypeOperation,
  submitMortgageInfoOperation,
  submitPropertyInformationOperation,
  submitUnresolvedAVMPropertyOperation,
  setLenderOperation,
  submitSelfReportedIncomeAssetsOperation,
  submitSelfReportedLiabilitiesOperation,
  submitSelfReportedCreditScoreOperation,
  inviteSecondaryApplicantOperation,
]
  .map((opertion) => opertion.success.type)
  .filter(Boolean);

const isUpdateBasicInfoSuccessOps = (targetOpe: PayloadActionCreator<any>): boolean => {
  return BASIC_INFO_UPDATE_ACTION_TYPES.includes(targetOpe.type);
};

const basicInfoReducer = createReducer<nullable<ResidentialApplicationBasicInfoResponse>>(
  initialState,
  (builder) =>
    builder
      .addCase(signOutOperation.pending, () => initialState)
      .addCase(
        fetchBasicInfoByUserIdOperation.success,
        (state, {payload: basicInfoResponseDto}) => basicInfoResponseDto
      )
      .addMatcher(isUpdateBasicInfoSuccessOps, (state, {payload}) => {
        return {
          ...state,
          ...payload,
        };
      })
);

export default basicInfoReducer;
