import {useEffect} from "react";
import {fields, forgotPassword} from "./SignInPage.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getIsOperationPending, getOperationFailure} from "store/selectors/operation";
import {OperationType} from "types/operation";
import {initialize, login, SignInPageFormPayload} from "./SignInPage.action";
import {getFixedT} from "util/languageUtil";
import {Typography} from "@mui/material";
import {trackEvent} from "util/eventUtil";
import {paperAirplane, TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {getSchema} from "./SignInPage.schema";
import {
  DoubleColumnPageTemplate,
  Form,
  Message,
  SubmitButton,
  TextFieldControlled,
} from "@pinch-financial/pinch-ui-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NavigationBar from "components/NavigationBar/NavigationBar";

const fixedT = getFixedT("signInPage");

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SignInPage = ({containerRef}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSignInPending = useSelector(getIsOperationPending)(OperationType.signIn);
  const signInFailure = useSelector(getOperationFailure)(OperationType.signIn);
  const isKeyboardOpen = useDetectKeyboardOpen();

  let signInFailureMessage = "";

  if (signInFailure) {
    signInFailureMessage = fixedT("errorMessages.error");
  }

  const formHook = useForm<SignInPageFormPayload>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(getSchema()),
  });

  const onSubmit = (values: SignInPageFormPayload) => {
    trackEvent(TrackingEventType.signInClickContinueButton);
    dispatch(login({...values, navigate}));
  };

  useEffect(() => {
    dispatch(initialize({navigate}));
  }, []);

  // Keep this so UI can update. React-hook-form problem.
  const {
    formState: {errors, touchedFields: touched},
  } = formHook;

  return (
    <DoubleColumnPageTemplate
      isMobileKeyboardOpen={isKeyboardOpen}
      containerRef={containerRef}
      iconSrc={paperAirplane}
      iconAlt={fixedT("iconAlt")}
      header={fixedT("header")}
      subheader={
        <span
          onClick={() => trackEvent(TrackingEventType.signInClickCreateAccount)}
          dangerouslySetInnerHTML={{__html: fixedT("subheader")}}
        ></span>
      }
      description={fixedT("description")}
      navigationBar={<NavigationBar />}
      nextButton={
        <SubmitButton isEnabled={!isSignInPending} onClick={formHook.handleSubmit(onSubmit)}>
          {fixedT("forwardButton")}
        </SubmitButton>
      }
    >
      <Form className={fields} onEnter={formHook.handleSubmit(onSubmit)}>
        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="email"
          type="email"
          label={fixedT("fields.email.label")}
          placeholder={fixedT("fields.email.placeholder")!}
          disabled={isSignInPending}
          autoComplete={"current-user"}
          required
        />

        <TextFieldControlled
          fullWidth
          formHook={formHook}
          name="password"
          type="password"
          label={fixedT("fields.password.label")}
          placeholder={fixedT("fields.password.placeholder")!}
          disabled={isSignInPending}
          autoComplete={"current-password"}
          required
        />
      </Form>
      <Typography
        sx={{mt: 1, mb: 1, fontSize: "14px"}}
        onClick={() => trackEvent(TrackingEventType.signInClickResetPassword)}
        className={forgotPassword}
        dangerouslySetInnerHTML={{__html: fixedT("forgetPassword")}}
      />
      <Message message={signInFailureMessage} />
    </DoubleColumnPageTemplate>
  );
};

export default SignInPage;
