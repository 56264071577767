import {createReducer} from "@reduxjs/toolkit";
import {setDefaultProviderId, setUserSelectedProviderId} from "store/actions/whiteLabel.action";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {signOutOperation} from "store/operation/operations";
import {
  fetchMortgageProvidersOperation,
  fetchWhitelabelOperation,
} from "store/operation/whitelabel";
import {WhitelabelState} from "types/whitelabelState";

const initialState: WhitelabelState = {
  whitelabel: undefined,
  selectedProviderId: undefined,
  bank: undefined,
  lender: undefined,
};

const whitelabelReducer = createReducer<WhitelabelState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.pending, () => initialState)
    .addCase(fetchWhitelabelOperation.success, (state, {payload: whitelabel}) => ({
      ...state,
      whitelabel,
    }))
    .addCase(fetchMortgageProvidersOperation.success, (state, {payload: providers}) => ({
      ...state,
      ...providers,
    }))
    .addCase(fetchBasicInfoByUserIdOperation.success, (state, {payload: basicInfoResponse}) => {
      const selectedProviderIdInApp = basicInfoResponse.mortgageDetails?.mortgageProvider;
      return {
        ...state,
        selectedProviderId: selectedProviderIdInApp || state.selectedProviderId,
      };
    })
    .addCase(setUserSelectedProviderId, (state, {payload: providerId}) => {
      state.selectedProviderId = providerId;
    })
    .addCase(setDefaultProviderId, (state, {payload: providerId}) => {
      state.defaultProviderId = providerId;
    })
);

export default whitelabelReducer;
