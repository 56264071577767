import {call, put, select, takeEvery} from "redux-saga/effects";
import {WithPayload} from "types/basic";
import {
  HandleNewUrl,
  NavigateStep,
  handleNewUrl,
  navigateStep,
  removeNavigationError,
  initializeStatus,
  storeCurrentStep,
  storeUrl,
  storeStepStatusChange,
  calculateStepStatusChange,
  storeNextUrl,
  initializeActiveRouteSequence,
  storeNavbarClickedStep,
  popUrlFromHistory,
  navigateBackLastPage,
  setActivePageSequence,
} from "./NavigationBar.action";
import {
  getCurrentStep,
  getStepStatusWelcome,
  getStepStatusBanking,
  getStepStatusCredit,
  getStepStatusPersonalProfile,
  getStepStatusSelectProduct,
  getStepStatusLenders,
  getStepStatusMortgageType,
  getStepStatusSignUp,
  getHasCompletedUploadDocuments,
  getLatestUrlFromHistory,
} from "store/selectors/navigationController";
import {logEmpty} from "components/utils/log";
import {MORTGAGE_PROVIDER_NOT_REQUIRED_URLS, USER_ACCOUNT_NOT_REQUIRED_URLS} from "types/route";
import {getDefaultProviderId, getHasUserSelectedProvider} from "store/selectors/whitelabel";
import {getHasUser} from "store/selectors/user";
import {StepStatusState} from "types/NavigationController";
import {AppRouteUrl, NavStep, WithNavigate} from "@pinch-financial/pinch-ui-components";
import {
  getHasUserAcceptedAssetsDataWarning,
  getHasUserAcceptedDocumentUploadWarning,
  getHasUserAcceptedLiabilitiesDataWarning,
} from "store/selectors/applicantMeta";
import {
  acceptMissingAssetsDataWarning,
  openUserAcceptedAssetsDataWarningModal,
} from "pages/SelfReportedAssetsPage/SelfReportedAssetsPage.action";
import {
  acceptMissingLiabilitiesDataWarning,
  openUserAcceptedLiabilitiesDataWarningModal,
} from "pages/SelfReportedLiabilitiesPage/SelfReportedLiabilitiesPage.action";
import {
  acceptMissingDocumentUploadWarning,
  openUserAcceptedDocumentUploadWarningModal,
} from "pages/DocumentUploadPage/DocumentUploadPage.action";
import {fetchAppPages} from "api/appPages";
import {PageSequence} from "types/pagesequence";
import {getIsPrimary, getMortgageProviderIdFromAppOrSelected} from "store/selectors/basicInfo";
import {WithDataError} from "types/api";
import {
  getHasSelfReportedAssets,
  getHasSelfReportedLiabilities,
} from "store/selectors/consolidatedSelectors";

export function* initializeNavbarStatus() {
  yield put(removeNavigationError());

  yield put(
    initializeStatus({
      [NavStep.WELCOME]: (yield select(getStepStatusWelcome)) as StepStatusState,
      [NavStep.LENDERS]: (yield select(getStepStatusLenders)) as StepStatusState,
      [NavStep.SIGN_UP]: (yield select(getStepStatusSignUp)) as StepStatusState,
      [NavStep.MORTGAGE_TYPE]: (yield select(getStepStatusMortgageType)) as StepStatusState,
      [NavStep.PERSONAL_PROFILE]: (yield select(getStepStatusPersonalProfile)) as StepStatusState,
      [NavStep.BANKING]: (yield select(getStepStatusBanking)) as StepStatusState,
      [NavStep.CREDIT]: (yield select(getStepStatusCredit)) as StepStatusState,
      [NavStep.SELECT_PRODUCT]: (yield select(getStepStatusSelectProduct)) as StepStatusState,
    })
  );
}

export function* fetchInitRouteSequenceNavStatus() {
  const appFiOrSelected: string = yield select(getMortgageProviderIdFromAppOrSelected);
  const defaultProviderId: string = yield select(getDefaultProviderId);
  const primary: boolean = yield select(getIsPrimary);

  const pageSequence: WithDataError<PageSequence[]> = yield call(
    fetchAppPages,
    appFiOrSelected || defaultProviderId,
    primary
  );
  yield put(setActivePageSequence(pageSequence.data || []));
  yield call(initializeNavbarStatus);
}

export function* onChangeNavigatationStep({
  payload: {url: nextUrl, step, navigate},
}: WithPayload<NavigateStep>): any {
  logEmpty(navigate);

  if (!!step) yield put(storeNavbarClickedStep(step));

  const isUserRequired = !USER_ACCOUNT_NOT_REQUIRED_URLS.includes(nextUrl as AppRouteUrl);
  const hasUser: boolean = yield select(getHasUser);
  if (isUserRequired && !hasUser) {
    console.warn("Redirecting to signin page");
    yield call(navigate, AppRouteUrl.SIGN_IN);
    return;
  }

  const hasUserSelectedProvider: boolean = yield select(getHasUserSelectedProvider);
  const isProviderRequiredForPage = !MORTGAGE_PROVIDER_NOT_REQUIRED_URLS.includes(
    nextUrl as AppRouteUrl
  );
  if (isProviderRequiredForPage && !hasUserSelectedProvider) {
    console.warn("Redirecting to landing page to complete welcome step and select provider");
    yield call(navigate, AppRouteUrl.LANDING_PAGE_URL);
    return;
  }

  const currentUrl = window.location.pathname;

  // special cases where we want user to accept warning before navigate away

  if (currentUrl === AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS) {
    const hasUserAcceptedAssetsDataWarning: boolean = yield select(
      getHasUserAcceptedAssetsDataWarning
    );
    const hasSelfAssets = yield select(getHasSelfReportedAssets);
    if (!hasSelfAssets && !hasUserAcceptedAssetsDataWarning) {
      // stay and ask user to accept warning for skipping self-reported assets
      yield put(openUserAcceptedAssetsDataWarningModal(true));
      yield put(acceptMissingAssetsDataWarning(false));
      yield put(storeNextUrl(nextUrl));
      return;
    }
  }

  if (currentUrl === AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_LIABILITIES) {
    const hasUserAcceptedLiabilitiesDataWarning: boolean = yield select(
      getHasUserAcceptedLiabilitiesDataWarning
    );
    const hasSelfLiabilities = yield select(getHasSelfReportedLiabilities);
    if (!hasSelfLiabilities && !hasUserAcceptedLiabilitiesDataWarning) {
      // stay and ask user to accept warning for skipping self-reported liabilities
      yield put(openUserAcceptedLiabilitiesDataWarningModal(true));
      yield put(acceptMissingLiabilitiesDataWarning(false));
      yield put(storeNextUrl(nextUrl));
      return;
    }
  }

  if (currentUrl === AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS) {
    const hasUserAcceptedDocumentUploadWarning: boolean = yield select(
      getHasUserAcceptedDocumentUploadWarning
    );
    const hasCompletedUploadDocuments: boolean = yield select(getHasCompletedUploadDocuments);
    if (!hasUserAcceptedDocumentUploadWarning && !hasCompletedUploadDocuments) {
      // stay and ask user to accept warning for skipping document upload
      yield put(openUserAcceptedDocumentUploadWarningModal(true));
      yield put(acceptMissingDocumentUploadWarning(false));
      yield put(storeNextUrl(nextUrl));
      return;
    }
  }

  // Here we allow user to navigate to next url
  yield call(navigate, nextUrl);
  return;
}

export function* onChangeUrl({payload: {step, url}}: WithPayload<HandleNewUrl>) {
  const previousStep: NavStep = yield select(getCurrentStep);
  // checks step completion when user changes steps
  if (previousStep !== step) {
    yield put(storeCurrentStep(step));
    yield put(calculateStepStatusChange(previousStep));
  }

  yield put(storeUrl(url));
}

export function* onUpdateStepStatus({payload: step}: WithPayload<NavStep>) {
  switch (step) {
    case NavStep.WELCOME:
      const stepStatusWelcome: StepStatusState = yield select(getStepStatusWelcome);
      yield put(storeStepStatusChange({step, ...stepStatusWelcome}));
      break;
    case NavStep.LENDERS:
      const stepStatusLenders: StepStatusState = yield select(getStepStatusLenders);
      yield put(storeStepStatusChange({step, ...stepStatusLenders}));
      break;
    case NavStep.SIGN_UP:
      const stepStatusSignUp: StepStatusState = yield select(getStepStatusSignUp);
      yield put(storeStepStatusChange({step, ...stepStatusSignUp}));
      break;
    case NavStep.MORTGAGE_TYPE:
      const stepStatusMortgageType: StepStatusState = yield select(getStepStatusMortgageType);
      yield put(storeStepStatusChange({step, ...stepStatusMortgageType}));
      break;
    case NavStep.PERSONAL_PROFILE:
      const stepStatusPersonalProfile: StepStatusState = yield select(getStepStatusPersonalProfile);
      yield put(storeStepStatusChange({step, ...stepStatusPersonalProfile}));
      break;
    case NavStep.BANKING:
      const stepStatusBanking: StepStatusState = yield select(getStepStatusBanking);
      yield put(storeStepStatusChange({step, ...stepStatusBanking}));
      break;
    case NavStep.CREDIT:
      const stepStatusCredit: StepStatusState = yield select(getStepStatusCredit);
      yield put(storeStepStatusChange({step, ...stepStatusCredit}));
      break;
    case NavStep.SELECT_PRODUCT:
      const stepStatusSelectProduct: StepStatusState = yield select(getStepStatusSelectProduct);
      yield put(storeStepStatusChange({step, ...stepStatusSelectProduct}));
      break;
    default:
      console.warn("Step is undefined step=", step);
  }
}

export function* onNavigateBackLastPage({payload: {navigate}}: WithPayload<WithNavigate>) {
  yield put(popUrlFromHistory());
  const previousUrl: AppRouteUrl = yield select(getLatestUrlFromHistory);
  yield call(navigate, previousUrl);
}

export default function* navigationHistorySaga() {
  yield takeEvery(navigateStep, onChangeNavigatationStep);
  yield takeEvery(handleNewUrl, onChangeUrl);
  yield takeEvery(calculateStepStatusChange, onUpdateStepStatus);
  yield takeEvery(initializeActiveRouteSequence, fetchInitRouteSequenceNavStatus);
  yield takeEvery(navigateBackLastPage, onNavigateBackLastPage);
}
