import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigatePayload} from "@pinch-financial/pinch-ui-components";

const namespace = "SINForm";

export interface EquifaxPageFormData {
  legalFirstName?: string;
  legalLastName?: string;
  sinNo?: string;
  dateOfBirth?: Date | string;
  sinRequired?: boolean;
}

export const submit = createAction<WithNavigatePayload<EquifaxPageFormData>>(
  actionTypeOf(namespace, "submit")
);
