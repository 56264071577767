import {isEmail} from "components/utils/stringUtil";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

export const getSchema = () => {
  const {t: fixedT} = useTranslation("", {keyPrefix: "signInPage"});

  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(fixedT("messages.invalidEmail")!)
      .test({
        name: "email",
        test(email, ctx) {
          if (isEmail(email)) return true;
          return ctx.createError({
            path: "email",
            message: fixedT("messages.invalidEmail")!,
          });
        },
      }),
    password: yup.string().trim().required(fixedT("messages.missingPassword")!),
  });
};
