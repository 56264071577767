import {WithNavigate} from "./../../types/ui";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {InviteSecondaryApplicantPageFormData} from "@pinch-financial/pinch-ui-components";

const namespace = "InviteSecondaryApplicant";

export const submitInviteSecondary = createAction<InviteSecondaryApplicantPageFormData>(
  actionTypeOf(namespace, "submitInviteSecondary")
);

export const submitNo = createAction<WithNavigate>(actionTypeOf(namespace, "submitNo"));
