import {WithDataError} from "types/api";
import {normalizedFetchApi} from "./util";
import {UUID} from "@pinch-financial/pinch-ui-components";
import {PageSequence} from "types/pagesequence";

const baseUrl = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/app-pages`;

export const fetchAppPages = async (
  financialInstitutionId: UUID,
  primary: boolean,
): Promise<WithDataError<PageSequence[]>> =>
  normalizedFetchApi(() => fetch(`${baseUrl}/${financialInstitutionId}?primary=${primary}`));
