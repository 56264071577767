import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";

export const INIT_STEPS = [
  AppRouteUrl.LANDING_PAGE_URL,
  AppRouteUrl.PROVINCE_SELECT_PAGE_URL,
  AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
  AppRouteUrl.PURCHASE_TIMEFRAME_URL,
  AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS,
  AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT,
  AppRouteUrl.BANK_OR_BROKER,
  AppRouteUrl.SIGN_UP,
];

export const MORTGAGE_PROVIDER_NOT_REQUIRED_URLS: string[] = [
  AppRouteUrl.LANDING_PAGE_URL,
  AppRouteUrl.PROVINCE_SELECT_PAGE_URL,
  AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
  AppRouteUrl.PURCHASE_TIMEFRAME_URL,
  AppRouteUrl.BANK_OR_BROKER,
  AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT,
  AppRouteUrl.ACTIVATE_ACCOUNT_URL,
  AppRouteUrl.RESEND_EMAIL_URL,
  AppRouteUrl.SECONDARY_SIGNUP,
  AppRouteUrl.RESET_PASSWORD_URL,
  AppRouteUrl.SIGN_IN,
  AppRouteUrl.LOGIN_CALLBACK,
];

export const USER_ACCOUNT_NOT_REQUIRED_URLS: string[] = [
  AppRouteUrl.LANDING_PAGE_URL,
  AppRouteUrl.PROVINCE_SELECT_PAGE_URL,
  AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
  AppRouteUrl.PURCHASE_TIMEFRAME_URL,
  AppRouteUrl.BANK_OR_BROKER,
  AppRouteUrl.ACTIVATE_ACCOUNT_URL,
  AppRouteUrl.RESEND_EMAIL_URL,
  AppRouteUrl.SECONDARY_SIGNUP,
  AppRouteUrl.RESET_PASSWORD_URL,
  AppRouteUrl.SIGN_IN,
  AppRouteUrl.SIGN_UP,
  AppRouteUrl.LOGIN_CALLBACK,
  AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS,
  AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT,
];

export const DIRECT_LENDER_BASIC_INFO_STEPS = [
  AppRouteUrl.SIGN_UP,
  AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT,
  AppRouteUrl.BASIC_INFO_URL_ADDRESS,
  AppRouteUrl.BASIC_INFO_URL_SELF_REPORTED_ASSETS,
  AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
  AppRouteUrl.SELF_REPORTED_CREDIT_DIRECT,
];

export const INDIRECT_LENDER_BASIC_INFO_STEPS = [
  AppRouteUrl.SIGN_UP,
  AppRouteUrl.BASIC_INFO_URL_EMPLOYMENT,
  AppRouteUrl.BASIC_INFO_URL_ADDRESS,
  AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO,
  AppRouteUrl.BASIC_INFO_URL_SIN_FORM,
  AppRouteUrl.BASIC_INFO_URL_UPLOAD_DOCUMENTS,
];

export const MORTGAGE_INFO_STEPS = [
  AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_INFORMATION,
  AppRouteUrl.MORTGAGE_INFO_URL_PROPERTY_INFO, // not preapproval
  AppRouteUrl.MORTGAGE_INFO_URL_MORTGAGE_TYPE,
];
