import * as styles from "./Page.module.scss";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import {isMobile} from "react-device-detect";
import classNames from "classnames";
import NavigationBar from "components/NavigationBar/NavigationBar";
import Box from "@mui/material/Box/Box";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import GeneralPageTemplate from "templates/GeneralPageTemplate/GeneralPageTemplate";
import {BaseProps} from "types/ui";

interface Props extends BaseProps {
  icon?: string;
  loading?: boolean;
}

const Page = ({icon = undefined, loading = false, children, className}: Props) => {
  const isKeyboardOpen = useDetectKeyboardOpen();

  return (
    <Box
      className={classNames(styles.root, className, {
        [styles.keyboardOpen]: isMobile && isKeyboardOpen,
      })}
    >
      <CssBaseline />
      <NavigationBar />
      <GeneralPageTemplate icon={icon} loading={loading}>
        {children}
      </GeneralPageTemplate>
    </Box>
  );
};

export default Page;
