import * as styles from "./Form.module.scss";
import {FormEvent, MouseEvent} from "react";
import {WithChildren, WithClassName} from "types/ui";
import classNames from "classnames";
import {noop} from "lodash";

interface Props extends WithClassName, WithChildren {
  onFocus?: (event: any) => void;
  onEnter?: (event?: any) => void;
}

export const preventDefaultPropagation = (
  event: FormEvent<HTMLFormElement> | MouseEvent<Element, MouseEvent>
) => {
  event.stopPropagation();
  event.preventDefault(); // e.g. by default <form> submit event will reload the whole page
};

const Form = ({children, className, onFocus = noop, onEnter = noop}: Props) => {
  return (
    <form
      className={classNames(styles.root, className)}
      onSubmit={(event) => preventDefaultPropagation(event)}
      onFocus={onFocus}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          preventDefaultPropagation(event);
          onEnter();
        }
      }}
    >
      {children}
    </form>
  );
};

export default Form;
