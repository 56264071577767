import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitSelfReportedCreditScoreOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {
  navigatePreviousStep,
  submit,
  SubmitCreditScorePagePayload,
} from "./SelfReportedCreditScorePage.action";
import {calculateStepStatusChange} from "components/NavigationBar/NavigationBar.action";
import {AppRouteUrl, NavStep, WithNavigate} from "@pinch-financial/pinch-ui-components";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {ConfigurationKey} from "types/configurations";
import {getIsProviderConfigurationValueEnabled} from "store/selectors/providerconfigurations";

export function* onSubmit({
  payload: {creditScore, onNextPage},
}: WithPayload<SubmitCreditScorePagePayload>) {
  const userId: string = yield select(getUserId);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    creditScore,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage?.();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitSelfReportedCreditScoreOperation.saga,
    userId,
    basicInfoRequest,
    Step.SELF_REPORTED_CREDIT_SCORE
  );
  yield put(calculateStepStatusChange(NavStep.CREDIT));
  if (Operation.isSuccess(result)) {
    onNextPage && onNextPage();
  }
}
export function* onNavigatePreviousStep({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  const userId: string = yield select(getUserId);
  const isSelfReportedCreditEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT
    )
  );
  const isSelfReportedAssetsEnabled: boolean = yield select(
    getIsProviderConfigurationValueEnabled(
      ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES
    )
  );

  if (!userId && isSelfReportedCreditEnabled) {
    // once the other tickets are done for self reported pages
    // this should be refactored
    const previousRoute = isSelfReportedAssetsEnabled
      ? AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO
      : AppRouteUrl.LANDING_PAGE_URL;
    navigate(previousRoute);
  } else {
    navigate(AppRouteUrl.BASIC_INFO_URL_ADD_BANK_INFO);
  }
}

export default function* creditScorePageSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(navigatePreviousStep, onNavigatePreviousStep);
}
