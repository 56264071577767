import {TRACK_EVENT_DEBOUNCE_WAIT_MS} from "appConstants";
import {debounce} from "lodash";
import mixpanel from "mixpanel-browser";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import memoizeOne from "memoize-one";

export const trackEvent = (event: TrackingEventType | string, data?: string) => {
  try {
    mixpanel.track(event, {
      ...(data && {data: data}),
    });
  } catch (error: any) {
    console.error(`Error tracking event: ${event}. ${error}`);
  }
};

export const trackEventDebounced = debounce(trackEvent, TRACK_EVENT_DEBOUNCE_WAIT_MS);

export const trackEventMemoized = memoizeOne(trackEventDebounced);

export function preventDefaultBehaviour(event: any) {
  event.preventDefault();
  event.stopPropagation();
}
