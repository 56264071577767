import {EmailPassword} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "SignInPage";

export type SignInPageFormPayload = EmailPassword & WithNavigate;

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "initialize"));

export const login = createAction<SignInPageFormPayload>(actionTypeOf(namespace, "login"));
