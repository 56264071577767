import {createSelector} from "@reduxjs/toolkit";
import {ConfigurationsState, ConfigurationKey, Configuration} from "types/configurations";
import {State} from "types/store";
import {isConfigurationValueTrue} from "util/configUtil";
import {getNumberFinancialInstitutions} from "./whitelabel";

export const get = (rootState: State): ConfigurationsState => rootState.providerConfigurations;

export const getProviderConfiguration = createSelector(get, (configState) => configState);

export const getIsProviderConfigurationValueEnabled = (key: ConfigurationKey) => {
  return createSelector(
    get,
    (configState) =>
      configState?.find((configuration: Configuration) => configuration.key === key)?.value ===
      "true"
  );
};

export const getConfigurationValue = (key: ConfigurationKey) => {
  return createSelector(
    get,
    (configState) =>
      configState?.find((configuration: Configuration) => configuration?.key === key)?.value
  );
};

export const getOnlyDocumentUploadRequirements = createSelector(get, (configState) => {
  return configState?.filter((configuration: Configuration) =>
    configuration?.key?.includes("documents.required")
  );
});

// Always show the broker or bank page if there are multiple FIs, so the user can choose which one to use.
export const getIsBrokerageOrBankingPageActive = createSelector(get, getNumberFinancialInstitutions, (configState, configuredFIs) => {
  return configuredFIs > 1 || configState?.some((configuration: Configuration) =>
    ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_BROKERAGE_OR_BANKING === configuration?.key && isConfigurationValueTrue(configuration)
  );
});

export const getIsSelfReportedCreditPageActive = createSelector(get, (configState) => {
  return configState?.some((configuration: Configuration) =>
    ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_CREDIT === configuration?.key && isConfigurationValueTrue(configuration)
  );
});

export const getIsSelfReportedAssetsLiabilitiesPageActive = createSelector(get, (configState) => {
  return configState?.some((configuration: Configuration) =>
    ConfigurationKey.PRODUCT_PROVIDER_ACTIVE_PAGES_SELF_REPORTED_ASSETS_LIABILITIES === configuration?.key && isConfigurationValueTrue(configuration)
  );
});

export const getIsAnyDocumentUploadRequirementsEnabled = createSelector(get, (configState) => {
  const requiredConfig = configState?.filter(
    (configuration: Configuration) =>
      configuration?.key?.includes("documents.required") && isConfigurationValueTrue(configuration)
  );
  return Boolean(requiredConfig?.length);
});

export const getIsAllDocumentUploadHidden = createSelector(get, (configState) => {
  const hiddenConfig = configState?.filter(
    (configuration: Configuration) =>
      configuration?.key?.includes("documents.hidden") && isConfigurationValueTrue(configuration)
  );
  return (
    hiddenConfig?.length ==
    Object.values(ConfigurationKey).filter((key) => key?.includes("documents.hidden"))?.length
  );
});

// Document Upload Page is active if there are any required documents 
// or there are some documents that are not hidden.
export const getIsDocumentUploadPageActive = createSelector(
  getIsAllDocumentUploadHidden,
  getIsAnyDocumentUploadRequirementsEnabled,
  (allHidden, anyRequired) => {
    return !allHidden || anyRequired;
  }
);

export const getIsPrimaryLogoDisplayed = createSelector(get, (configState) => {
  return !!configState?.some(
    (configuration: Configuration) =>
      configuration?.key === ConfigurationKey.PRODUCT_PROVIDER_USE_PRIMARY_LOGO &&
      isConfigurationValueTrue(configuration)
  );
});
