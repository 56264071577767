import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authorizationCodeCallback} from "store/actions/auth.action";
import Page from "templates/Page/Page";

const AuthorizationCodeCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authorizationCodeCallback({navigate}));
  }, []);

  return <Page loading />;
};

export default AuthorizationCodeCallback;
