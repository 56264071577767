import {OperationType} from "types/operation";
import {Operation} from "./Operation";
import {
  fetchFinancialInstitutionByAppOrAuthIdApi,
  fetchFinancialInstitutionByIdApi,
} from "api/financialInstitution";
import {FinancialInstitutionBasicResponse} from "types/financialInstitutionBasicResponse";

export const fetchAppAssociatedFinancialInstitutionOperation =
  new Operation<FinancialInstitutionBasicResponse>(
    OperationType.fetchAppAssociatedFinancialInstitution,
    fetchFinancialInstitutionByIdApi
  );

export const fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation =
  new Operation<FinancialInstitutionBasicResponse>(
    OperationType.fetchAppAssociatedFinancialInstitutionByAppOrAuthId,
    fetchFinancialInstitutionByAppOrAuthIdApi
  );
