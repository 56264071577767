import {createReducer} from "@reduxjs/toolkit";
import {
  fetchProviderConfigurationsOperation,
  fetchWhiteLabelConfigurationsOperation,
  signOutOperation,
} from "store/operation/operations";
import {ConfigurationsState} from "types/configurations";

const whiteLabelState: ConfigurationsState = null;

export const whiteLabelConfigurationsReducer = createReducer<ConfigurationsState>(
  whiteLabelState,
  (builder) =>
    builder
      .addCase(signOutOperation.pending, () => whiteLabelState)
      .addCase(fetchWhiteLabelConfigurationsOperation.success, (state, {payload}) =>
        payload.filter((config) => !config?.key?.includes("organization.documents"))
      )
);

const mortgageProviderState: ConfigurationsState = null;

export const mortgageProviderConfigurationsReducer = createReducer<ConfigurationsState>(
  mortgageProviderState,
  (builder) =>
    builder
      .addCase(signOutOperation.pending, () => mortgageProviderState)
      .addCase(fetchProviderConfigurationsOperation.success, (state, {payload}) => payload)
);
