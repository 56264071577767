import {logEmpty} from "components/utils/log";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {submitSelfReportedIncomeAssetsOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {Step} from "@pinch-financial/pinch-ui-components";
import {submit, acceptMissingAssetsDataWarning} from "./SelfReportedAssetsPage.action";
import {SelfReportedAssetDto} from "types/dto/selfReportedAssetDto";
import {Currency} from "types/enums/currency";
import {SelfReportedAssetType} from "types/enums/selfReportedAssetType";
import {saveBasicInfo} from "store/actions/applicantMeta.action";
import {
  ResidentialApplicationBasicInfoRequest,
  SelfReportedAssetsPageFormData,
  WithNavigatePayload,
} from "@pinch-financial/pinch-ui-components";
import {isBlank} from "components/utils/stringUtil";

export function* onSubmit({
  payload: {navigate, data, shouldStay, onNextPage},
}: WithPayload<WithNavigatePayload<SelfReportedAssetsPageFormData>>) {
  logEmpty(navigate, "navigate");
  const userId: string = yield select(getUserId);

  let otherAssets =
    data.otherAssets?.map(
      (other) =>
        ({
          type: SelfReportedAssetType.OTHER,
          amount: {
            amount: other.amount,
            currency: Currency.CAD,
          },
          description: other.description,
        } as SelfReportedAssetDto)
    ) || [];

  let assets: SelfReportedAssetDto[] = [
    {
      type: SelfReportedAssetType.SAVINGS,
      amount:
        !isBlank(data.savings) && !isNaN(data.savings!)
          ? {amount: data.savings!, currency: Currency.CAD}
          : undefined,
    },
    {
      type: SelfReportedAssetType.CHEQUING,
      amount:
        !isBlank(data.chequing) && !isNaN(data.chequing!)
          ? {amount: data.chequing!, currency: Currency.CAD}
          : undefined,
    },
    {
      type: SelfReportedAssetType.RRSP,
      amount:
        !isBlank(data.rrsp) && !isNaN(data.rrsp!)
          ? {amount: data.rrsp!, currency: Currency.CAD}
          : undefined,
    },
    {
      type: SelfReportedAssetType.TFSA,
      amount:
        !isBlank(data.tfsa) && !isNaN(data.tfsa!)
          ? {amount: data.tfsa!, currency: Currency.CAD}
          : undefined,
    },
    ...otherAssets,
  ].filter((asset) => asset.amount !== undefined);

  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    selfReportedAssets: assets,
  };

  yield put(saveBasicInfo(basicInfoRequest));

  if (!userId) {
    onNextPage && onNextPage();
    return;
  }

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    submitSelfReportedIncomeAssetsOperation.saga,
    userId,
    basicInfoRequest,
    Step.SELF_REPORTED_ASSETS
  );
  if (Operation.isSuccess(result)) {
    yield put(acceptMissingAssetsDataWarning(true)); // User successfully submitted assets, setting the flag just so user is not get warned any more.
    !shouldStay && onNextPage && onNextPage();
  }
}

export function* onAcceptMissingAssetsDataWarning({payload}: WithPayload) {
  const {hasUserAcceptedAssetsDataWarning} = payload;
  if (hasUserAcceptedAssetsDataWarning) {
    yield put(acceptMissingAssetsDataWarning(hasUserAcceptedAssetsDataWarning));
  }
}

export default function* selfReportedAssetsSaga() {
  yield takeEvery(submit, onSubmit);
  yield takeEvery(acceptMissingAssetsDataWarning, onAcceptMissingAssetsDataWarning);
}
