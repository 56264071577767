import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";

const namespace = "PurchaseTimeframePage";

export type PurchaseTimeframePagePayload = {
  onNextPage?: () => any;
} & Pick<ResidentialApplicationBasicInfoRequest, "purchaseTimeframe">;

export const submit = createAction<PurchaseTimeframePagePayload>(actionTypeOf(namespace, "submit"));
