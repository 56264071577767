import {Password, UUID} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "SetPasswordPage";

export type SetPasswordPagePayload = Password & WithNavigate;

export type InitializePayload = {
  token: string | null;
  appId: UUID | null;
};

export const initialize = createAction<InitializePayload>(actionTypeOf(namespace, "initialize"));

export const submit = createAction<SetPasswordPagePayload>(actionTypeOf(namespace, "setPasword"));
