import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "CalculateQualificationPage";

export const initialize = createAction<WithNavigate>(actionTypeOf(namespace, "intialize"));

export const fetchApplicationStatus = createAction<WithNavigate>(
  actionTypeOf(namespace, "fetchApplicationStatus")
);
