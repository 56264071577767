import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";
import {ResidentialApplicationQualificationsResponse} from "@pinch-financial/pinch-ui-components/dist/types/dto/residentialApplicationQualificationsResponse";

const namespace = "ProductSelectPage";

export interface SubmitLenderPayload extends WithNavigate {
  lender: ResidentialApplicationQualificationsResponse;
}

export const submit = createAction<SubmitLenderPayload>(actionTypeOf(namespace, "submit"));
