import {getFixedT} from "util/languageUtil";
import {SuccessPage as SuccessPageUI} from "@pinch-financial/pinch-ui-components";
import NavigationBar from "components/NavigationBar/NavigationBar";

const fixedT = getFixedT("successPage");

interface Props {
  containerRef?: React.RefObject<HTMLDivElement>;
}

const SuccessPage = ({containerRef}: Props) => {
  return (
    <SuccessPageUI
      containerRef={containerRef}
      header={<span dangerouslySetInnerHTML={{__html: fixedT("header")}} />}
      body1={fixedT("body1")}
      body2={fixedT("body2")}
      navigationBar={<NavigationBar />}
    />
  );
};

export default SuccessPage;
