import store from "../index";
import {setOperationPending, setOperationSuccess, setOperationFailure} from "../actions/operation";
import {OperationType, OperationStatus} from "types/operation";
import {createAction, PayloadActionCreator, Selector} from "@reduxjs/toolkit";
import {UNKNOWN_API_ERROR} from "types/error";
import {WithDataError} from "types/api";
import {State} from "types/store";
import {AuthError} from "types/auth";
import {SagaResult} from "types/basic";
import {ApiError} from "types/ApiError";
import {signOut} from "store/actions/auth.action";

export class Operation<PAYLOAD = any> {
  public readonly pending: PayloadActionCreator<void>;
  public readonly success: PayloadActionCreator<PAYLOAD>;
  public readonly failure: PayloadActionCreator<ApiError>;

  private prevSagaTimeout?: NodeJS.Timeout;

  constructor(
    public operationType: OperationType,
    public api: (...args: any[]) => Promise<WithDataError<PAYLOAD>>,
    public selector?: Selector<State, PAYLOAD | any> | any
  ) {
    this.pending = createAction<void>(`${this.operationType}_${OperationStatus.pending}`);
    this.success = createAction<PAYLOAD>(`${this.operationType}_${OperationStatus.success}`);
    this.failure = createAction<ApiError>(`${this.operationType}_${OperationStatus.failure}`);

    this.cacheSaga = this.cacheSaga.bind(this);
    this.saga = this.saga.bind(this);
    this.doSaga = this.doSaga.bind(this);
  }

  async cacheSaga(...args: any[]): Promise<PAYLOAD | ApiError> {
    if (this.selector) {
      const value = this.selector(store.getState());
      if (value != null) {
        return value;
      }
    }
    return this.saga(...args);
  }

  async saga(...args: any[]): Promise<SagaResult<PAYLOAD>> {
    if (this.prevSagaTimeout) {
      clearTimeout(this.prevSagaTimeout);
    }

    return new Promise((resolve) => {
      this.prevSagaTimeout = setTimeout(() => {
        this.doSaga(...args)
          .then(resolve)
          .catch(resolve)
          .finally(() => {
            this.prevSagaTimeout = undefined;
          });
      }, 300);
    });
  }

  private async doSaga(...args: any[]): Promise<SagaResult<PAYLOAD>> {
    try {
      store.dispatch(setOperationPending(this.operationType));
      store.dispatch(this.pending());

      const {data, error} = await this.api(...args);
      if (error) {
        const errorClassInstance = new ApiError(error, error.status);
        store.dispatch(this.failure(errorClassInstance));
        store.dispatch(setOperationFailure([this.operationType, error]));

        if (error.status === 401) {
          console.error("401 - unauthorized error, will logout user now.");
          store.dispatch(signOut());
        }
        return errorClassInstance;
      }

      store.dispatch(this.success(data));
      store.dispatch(setOperationSuccess(this.operationType));
      return data as PAYLOAD;
    } catch (e: Error | AuthError | any) {
      console.error("Internal error - The api function is not supposed to throw exception.");
      const errorClassInstance = new ApiError(UNKNOWN_API_ERROR);

      store.dispatch(this.failure(errorClassInstance));
      store.dispatch(setOperationFailure([this.operationType, {message: UNKNOWN_API_ERROR}]));

      return errorClassInstance;
    }
  }

  public static isError(operationSagaResult: SagaResult<any>): operationSagaResult is ApiError {
    return operationSagaResult instanceof ApiError;
  }

  public static isSuccess<T = any>(
    operationSagaResult: SagaResult<T>
  ): operationSagaResult is SagaResult<T> {
    return !Operation.isError(operationSagaResult);
  }
}
