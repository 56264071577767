import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {acceptPrivacyOperation} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {getUserId} from "store/selectors/user";
import {WithPayload, SagaResult} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {PolicyType} from "types/enums/policyType";
import {WithNavigate} from "types/ui";
import {acceptPrivacy} from "./DisclosurePage.action";
import {Step} from "@pinch-financial/pinch-ui-components";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {getConsolidatedBasicInfo} from "store/selectors/basicInfo";
import {getNextPageUrl} from "util/routeUtil";
import {
  getActiveRouteSequence,
  getHasNavbarAssetLiabilitiesStepClicked,
} from "store/selectors/navigationController";
import {saveBasicInfo} from "store/actions/applicantMeta.action";

export function* onAcceptPrivacy({payload}: WithPayload<WithNavigate>) {
  const {navigate} = payload;
  const userId: string = yield select(getUserId);
  const basicInfoRequest: Partial<ResidentialApplicationBasicInfoRequest> = {
    policyType: PolicyType.PRIVACY,
  };
  const activeRouteSequence: AppRouteUrl[] = yield select(getActiveRouteSequence);
  const basicInfo: ResidentialApplicationBasicInfoResponse = yield select(getConsolidatedBasicInfo);
  const hasNavbarAssetLiabilitiesStepClicked: boolean = yield select(
    getHasNavbarAssetLiabilitiesStepClicked
  );

  const nextPage: AppRouteUrl = yield call(
    getNextPageUrl,
    AppRouteUrl.DISCLOSURE_URL,
    activeRouteSequence,
    basicInfo,
    hasNavbarAssetLiabilitiesStepClicked
  );

  yield put(saveBasicInfo(basicInfoRequest));

  const result: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
    acceptPrivacyOperation.saga,
    userId,
    basicInfoRequest,
    Step.ACCEPT_POLICY
  );
  if (Operation.isSuccess(result)) {
    navigate(nextPage);
  }
}

export default function* disclosurePageSaga() {
  yield takeEvery(acceptPrivacy, onAcceptPrivacy);
}
