import {Drawer} from "@mui/material";
import {DRAWER_WIDTH} from "appConstants";
import {WithChildren} from "types/ui";

interface Props extends WithChildren {
  handleDrawerToggle: () => void;
  isMobileDrawerOpen: boolean;
}
const container = window !== undefined ? () => window.document.body : undefined;

export default function NavigationBarActionSection({
  handleDrawerToggle,
  isMobileDrawerOpen,
  children,
}: Props) {
  return (
    <Drawer
      PaperProps={{elevation: 10}}
      container={container}
      variant="temporary"
      open={isMobileDrawerOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        zIndex: 1500,
        display: "block",
        "& .MuiDrawer-paper": {boxSizing: "border-box", width: DRAWER_WIDTH},
      }}
    >
      {children}
    </Drawer>
  );
}
