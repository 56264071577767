import {AddressInfoPageFormData, WithNavigatePayload} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "AddressInfo";

export const submitAddressInfo = createAction<WithNavigatePayload<AddressInfoPageFormData>>(
  actionTypeOf(namespace, "submit")
);
