import {AppBar, Toolbar, IconButton, Box} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CompanyLogo from "components/CompanyLogo/CompanyLogo";
import SaveButton from "components/SaveButton/SaveButton";
import {PageRef} from "@pinch-financial/pinch-ui-components";
import {RefObject} from "react";

interface Props {
  handleDrawerToggle: () => void;
  isMobileView: boolean;
  pageRef?: RefObject<PageRef> | null; // The ref to the page that exposes the submitForm method.
}

export default function NavigationBarTop({handleDrawerToggle, isMobileView, pageRef}: Props) {
  return (
    <AppBar
      position="fixed"
      elevation={3}
      sx={{
        zIndex: {mobile: "1499", laptop: "10"},
        width: {laptop: "13%"},
        boxShadow: {laptop: "unset"},
        backgroundColor: isMobileView ? "white" : "#f2f2f2",
      }}
    >
      <Toolbar sx={{minHeight: 0, maxHeight: "56px"}}>
        {isMobileView && (
          <>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2}}
            >
              <MenuIcon />
            </IconButton>
            <Box>
              <CompanyLogo />
            </Box>
            <SaveButton displayFor="mobile" pageRef={pageRef} />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
