import i18next from "i18next";
import * as styles from "./RegistrationComplete.module.scss";
import {useSelector} from "react-redux";

import {getIsEmailVerified} from "store/selectors/auth";
import {getHasUserAccount} from "store/selectors/user";

const RegistrationComplete = () => {
  const hasUser = useSelector(getHasUserAccount);
  const isEmailVerified = useSelector(getIsEmailVerified);

  return (
    <div className={styles.root}>
      <div>
        {i18next.t("registrationComplete.prompt", {
          context: hasUser && !isEmailVerified ? "verify" : "forgot",
        })}
      </div>
    </div>
  );
};

export default RegistrationComplete;
