import i18next from "i18next";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {
  fetchBasicInfoByUserIdOperation,
  fetchFinancialInstitutionIdAndNameOperation,
} from "store/operation/basicInfo";
import {Operation} from "store/operation/Operation";
import {registerSecondaryUserOperation} from "store/operation/user";
import {getMortgageProviderIdFromAppOrSelected} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {getWhitelabelId} from "store/selectors/whitelabel";
import {SagaResult, WithPayload} from "types/basic";
import {ResidentialApplicationBasicInfoResponse} from "types/dto/residentialApplicationBasicInfoResponse";
import {User} from "types/dto/user";
import {
  fetchSelectedProvider,
  initialize,
  registerSecondaryUser,
} from "./SecondarySignUpPage.action";
import {RegisterSecondaryUserPayload, UUID} from "@pinch-financial/pinch-ui-components";
import {fetchInitRouteSequenceNavStatus} from "components/NavigationBar/NavigationBar.saga";
import {fetchProviderConfigurationsOperation} from "store/operation/operations";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {selectProvider} from "pages/BankOrBrokerPage/BankOrBrokerPage.action";
import {FinancialInstitutionSummaryDto} from "types/dto/financialInstitutionSummary";
import {PayloadAction} from "@reduxjs/toolkit";
import {setUserSelectedProviderId} from "store/actions/whiteLabel.action";
import {getCurrentRouteOrFirstAfterSignUp} from "store/selectors/consolidatedSelectors";
import {fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation} from "store/operation/financialInstitution";

export function* onRegisteringSecondary({
  payload: {navigate, appId, ...userRequest},
}: WithPayload<RegisterSecondaryUserPayload>) {
  const whiteLabelId: string = yield select(getWhitelabelId);
  const secondarySignUpResult: SagaResult<User> = yield call(
    registerSecondaryUserOperation.saga,
    appId,
    {...userRequest, languagePreference: i18next.language},
    whiteLabelId,
    location.host
  );
  if (Operation.isSuccess(secondarySignUpResult)) {
    const userId: string = yield select(getUserId);
    const basicInfoResult: SagaResult<ResidentialApplicationBasicInfoResponse> = yield call(
      fetchBasicInfoByUserIdOperation.saga,
      userId
    );

    if (Operation.isSuccess(basicInfoResult)) {
      const providerId: string = yield select(getMortgageProviderIdFromAppOrSelected);
      yield call(fetchProviderConfigurationsOperation.saga, providerId);
      // The navigation bar should be re-initialized so MORTGAGE_TYPE step is hidden.
      yield call(fetchInitRouteSequenceNavStatus);
      const nextRoute: AppRouteUrl = yield select(getCurrentRouteOrFirstAfterSignUp);
      console.info("Redirect to", nextRoute);
      navigate(nextRoute);
    }
  }
}

export function* initializeSecondarySignUpPage({payload: {appId}}: PayloadAction<{appId: UUID}>) {
  yield call(fetchAppAssociatedFinancialInstitutionByAppOrAuthIdOperation.saga, appId);
}

export function* onFetchSelectedProvider({payload: {appId}}: PayloadAction<{appId: UUID}>) {
  const financialInstitutionResult: FinancialInstitutionSummaryDto = yield call(
    fetchFinancialInstitutionIdAndNameOperation.saga,
    appId
  );
  if (Operation.isSuccess(financialInstitutionResult)) {
    yield put(setUserSelectedProviderId(financialInstitutionResult?.publicId!));
    yield put(selectProvider(true));
  }
}

export default function* secondarySignUpPageSaga() {
  yield takeEvery(registerSecondaryUser, onRegisteringSecondary);
  yield takeEvery(fetchSelectedProvider, onFetchSelectedProvider);
  yield takeEvery(initialize, initializeSecondarySignUpPage);
}
