import {createReducer} from "@reduxjs/toolkit";
import {
  removeNavigationError,
  initializeStatus,
  storeCurrentStep,
  storeNavigationError,
  storeUrl,
  storeStepStatusChange,
  storeNextUrl,
  removeRouteFromSequence,
  storeNavbarClickedStep,
  popUrlFromHistory,
  setSlideDirection,
  setSkipAnimation,
  setActivePageSequence,
} from "components/NavigationBar/NavigationBar.action";
import {signOutOperation} from "store/operation/operations";
import {NavigationState, StepStatusRecord, StepStatusState} from "types/NavigationController";
import {NavStep} from "@pinch-financial/pinch-ui-components";
import {RouteUrlValue} from "types/routeUrlValue";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";

const initialStepStatusState: StepStatusState = {
  optional: false,
  hidden: false,
  completed: false,
  visited: false,
  hasVisitedViaNavbar: false,
};

const initialStepStatusRecord: StepStatusRecord = {
  [NavStep.WELCOME]: {...initialStepStatusState},
  [NavStep.LENDERS]: {...initialStepStatusState},
  [NavStep.SIGN_UP]: {...initialStepStatusState},
  [NavStep.MORTGAGE_TYPE]: {...initialStepStatusState},
  [NavStep.PERSONAL_PROFILE]: {...initialStepStatusState},
  [NavStep.BANKING]: {...initialStepStatusState},
  [NavStep.CREDIT]: {...initialStepStatusState},
  [NavStep.SELECT_PRODUCT]: {...initialStepStatusState},
};

export const initialState: NavigationState = {
  history: [],
  currentStep: undefined,
  stepStatus: initialStepStatusRecord,
  activePageSequence: [], // New state for the active route sequence
  slideDirection: undefined,
  skipAnimation: false,
};

const navigationHistoryReducer = createReducer<NavigationState>(initialState, (builder) =>
  builder
    .addCase(signOutOperation.pending, () => initialState)
    .addCase(initializeStatus, (state, {payload: stepsStatusRecord}) => {
      return {
        ...state,
        stepStatus: {...state.stepStatus, ...stepsStatusRecord},
      };
    })
    .addCase(storeUrl, (state, {payload: pathname}) => {
      const previousHistory: RouteUrlValue[] = state.history;
      const isHistoryEmpty = !Boolean(history.length);
      // prevent adding the same url to the history
      const isLatestPlaceInHistoryNotCurrentPathname =
        previousHistory[previousHistory.length - 1] !== pathname;

      if (
        pathname !== AppRouteUrl.LOGIN_CALLBACK &&
        (isHistoryEmpty || isLatestPlaceInHistoryNotCurrentPathname)
      ) {
        return {
          ...state,
          history: [...state.history, pathname],
        };
      }
      return state;
    })
    .addCase(storeNextUrl, (state, {payload: pathname}) => {
      return {...state, nextUrl: pathname};
    })
    .addCase(storeStepStatusChange, (state, {payload: {step, ...stepStatus}}) => {
      const updatedStepStatus = {...state.stepStatus[step], ...stepStatus};
      return {
        ...state,
        stepStatus: {...state.stepStatus, [step]: updatedStepStatus},
      };
    })
    .addCase(storeCurrentStep, (state, {payload: step}) => {
      return {...state, currentStep: step};
    })
    .addCase(storeNavigationError, (state, {payload: error}) => {
      return {...state, error};
    })
    .addCase(storeNavbarClickedStep, (state, {payload: step}) => {
      const updatedStep = {...state.stepStatus[step], hasVisitedViaNavbar: true};
      return {
        ...state,
        stepStatus: {...state.stepStatus, [step]: updatedStep},
      };
    })
    .addCase(removeNavigationError, (state) => {
      return {...state, error: undefined};
    })
    .addCase(setActivePageSequence, (state, {payload}) => {
      return {...state, activePageSequence: payload};
    })
    .addCase(removeRouteFromSequence, (state, {payload}) => {
      return {
        ...state,
        activePageSequence: state.activePageSequence?.filter((page) => page.route !== payload),
      };
    })
    .addCase(popUrlFromHistory, (state) => {
      const previousHistory: RouteUrlValue[] = state.history;
      const newHistory = previousHistory.slice(0, previousHistory.length - 1);
      return {
        ...state,
        history: newHistory,
      };
    })
    .addCase(setSlideDirection, (state, {payload}) => {
      return {...state, slideDirection: payload};
    })
    .addCase(setSkipAnimation, (state, {payload}) => {
      return {...state, skipAnimation: payload};
    })
);

export default navigationHistoryReducer;
