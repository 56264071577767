import {UUID} from "@pinch-financial/pinch-ui-components";
import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "store/actions/util";
import {WithNavigate} from "types/ui";

const namespace = "BankOrBrokerPage";

export type NextWithBankPayload = WithNavigate & {providerId: UUID};
export const nextWithBank = createAction<NextWithBankPayload>(
  actionTypeOf(namespace, "nextWithBank")
);

export const nextWithBroker = createAction<WithNavigate>(actionTypeOf(namespace, "nextWithBroker"));

export const selectProvider = createAction<boolean>(actionTypeOf(namespace, "selectProvider"));
