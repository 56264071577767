import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "./util";
import {UUID} from "@pinch-financial/pinch-ui-components";

const namespace = "whiteLabel";

export const setUserSelectedProviderId = createAction<UUID>(
  actionTypeOf(namespace, "setUserSelectedProviderId")
);

export const setDefaultProviderId = createAction<UUID>(
  actionTypeOf(namespace, "setDefaultProviderId")
);
